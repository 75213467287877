import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNdiFields, getNdiFields, getTicketSummary, addOrUpdateTicketData } from '../../actions/NdiFieldsAction';
import { Validations } from '../../utils/validations';
import { CommonArrays } from '../../utils/CommonArrays';
import moment from "moment";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import {getLang} from "../../composables/genLang.js"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ZAFClient from 'zendesk_app_framework_sdk';
import GavelIcon from '@material-ui/icons/Gavel';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import NdiFieldsFormzendesk from './NdiFieldsFormzendesk';
//import NdiNeoChargeDeskzendesk from './NdiNeoChargeDeskzendesk';
import NeoVentesFormB2b from '../neob2bclients/NeoVentesForm';
import  getProvinceTaxInfo from "../../appTaxes";
import Alert from '@material-ui/lab/Alert';
import history from '../../history';
import Toastr from "../../messages";
import  Loader from "../../loader";
import  { API_DOMAIN } from "../../appConfig";


//import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';


// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import InfoIcon from '@material-ui/icons/Info';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ViewListIcon from '@material-ui/icons/ViewList';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import UserLogout  from "../../UserLogout";

import ChangeLanguage from '../../changeLanguage';
import { Link } from "react-router-dom";


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ThumbUpAltOutlined } from '@material-ui/icons';
import NdiInterestConflict from './NdiInterestConflict';
import { genLang } from '../../composables/genLang';


let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['text_label']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['placeholder']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_type']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_default_value']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['mask']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_service']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['field_dataset']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['tag_id']={check:false, chkType:'int', message:'incorrect value'};

let zafClient = ZAFClient.init();

class NdiFieldsMiniBoardzendesk extends React.Component {
  validation=new Validations();
  commonArrays=new CommonArrays();
  actionMode='new';
  backTicketId=0;
  ticketInUse='';
  clientOnZendeskSCR=0;
  neoemail="";
  zendlistElementChanges=[];
  zendlistElementChangesval=[];
  zendeskChangeLock=false;
  activeTab=0;
  isZendekBusy=false;

  constructor(props) {
    super(props);
    this.state = {
      isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
      dynamicFormData:[], ticket:{}, realticket_id:'', //191807 197504 52583 191807
      ticketSummary:{},

      id:0,
      name:'', e_name: false,
      text_label:'', e_text_label: false,
      placeholder:'', e_placeholder: false,
      field:'', e_field: false,
      field_type:'', e_field_type: false,
      field_default_value:'', e_field_default_value: false,
      mask:'', e_mask: false,
      field_service:'', e_field_service: false,
      field_dataset:'', e_field_dataset: false,
      tag_id:'', e_tag_id: false,
      zendchangeCounter:0, activeTab:0,
      clientInfo:{ },
		  clientAddress:{ },
      client_id:'', openNeoSearch:false,
      productId: ""

    }
	// const locurl=window.location.href;
	// const patt=/([0-9]+)(\/edit)$/;
	// if(patt.test(locurl)){
	// 	this.actionMode='edit';
	// }else{
	// 	this.actionMode='new';
  // }

  const locurl=(""+window.location.href).replace("#", "");
	const patt=/([0-9]+)(\/edit)$/;
	const patt2=/([0-9]+)(\/ticket)(.*)$/;
	if(patt.test(locurl)){
		this.actionMode='edit';
	}else if(patt2.test(locurl)){
		this.actionMode='ticket';
		const regex = /([0-9]+)(\/ticket)(.*)$/g; ///[A-Z]/g;
		const foundText = locurl.match(regex);
		const cleanId=(""+foundText[0]).replace("/invoice", "");
    this.backTicketId=parseInt(cleanId);
    console.log("before neeeeeeeeeeoemail.... ");
    if((""+locurl).indexOf("neoemail") >-1){
      //const query = new URLSearchParams(this.props.location.search);
      //const neoemail = query.get('token');
      const neoemail = this.getQueryVariable("neoemail");
      if(neoemail!==false){ this.neoemail=neoemail; }
      console.log("neeeeeeeeeeoemail: "+neoemail+"  vs "+this.neoemail);
    }
    //this.ticketInUse=''+cleanId;
		//console.log(foundText, cleanId, this.backInvoiceId);
	}else{
		this.actionMode='new';
  }

  //console.log(' action mode '+this.actionMode);


  //const locurl=window.location.href;
	let path=this.props.location;
	const title=document.title;
	/*let urlParamZendesk=localStorage.getItem("zendeskzat");
	console.log("000MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk);
	if(urlParamZendesk!==undefined){
		urlParamZendesk=urlParamZendesk+"&xqNeO=1";
		console.log("000repush MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk);
		//history.push(`/zendesk/${urlParamZendesk}`);
		const new_URL=locurl+urlParamZendesk;
		window.history.replaceState( {} , title, new_URL );
	}*/
  }


  getQueryVariable(variable)
  {
          const query = window.location.search.substring(1);
          //console.log(query)//"app=article&act=news_content&aid=160990"
          const vars = query.split("&");
          //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
          for (var i=0;i<vars.length;i++) {
            const pair = vars[i].split("=");
            //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
            if(pair[0] == variable){return pair[1];}
          }
          return(false);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true });
		  }
	  }
  };


  componentDidMount(){
    if(this.props.currentTabTicket!==undefined){
      // if(this.backTicketId >0){
      //   this.setState({ ["realticket_id"]: this.backTicketId });
      this.backTicketId=parseInt(this.props.currentTabTicket);
    }

    this.forceLogoutSystem(null);
    this.componentReloadData();

    const interval= setInterval(() => {
      //handleSaleGoal(null);
      this.forceLogoutSystem(null);
    }, 300000);

    //return () => clearInterval(interval);

  }

  componentDidUpdate(prevProps, prevState){
    if (prevState?.ticketSummary !== this.state.ticketSummary){
      const {transactions} = this.state?.ticketSummary ?? []
      if(transactions!==null && transactions!==undefined){ 
        if(transactions.id!==null && transactions.id!==undefined){
          const productId=transactions?.id_products ?? "";  
          this.setState({productId});
        }else if(transactions[0].id!==null && transactions[0].id!==undefined){
          const productId=transactions[0].id_products ?? "";  
          this.setState({productId});
        }
      } 
    }
  }

  forceLogoutSystem = (event) => {
    const dd=new Date();
		const ddIso=new Date(dd.getFullYear(), dd.getMonth(), dd.getDate());
		const ddStr=""+dd.getFullYear()+""+dd.getMonth()+""+dd.getDate();
		//localStorage.setItem("uLogTracking", ddIso.toISOString());
    //localStorage.setItem("uLogTracking2", ddStr);
    const uLogTracking2=localStorage.getItem("uLogTracking2");
    if(uLogTracking2!==undefined){
      if(uLogTracking2!==ddStr){
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("rid");
        localStorage.removeItem("profile");
        history.push(`./Login`);
      }
    }else{
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("rid");
        localStorage.removeItem("profile");
        history.push(`./Login`);
    }


  };

  componentDidMount000000() {
    let stepRunned=0;
    // const script = document.createElement("script");
		// script.src = `https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js`;
		// script.async = true;
		// document.body.appendChild(script);
    // document.body.removeChild(script); // remove it so in each time you visit this route it won't create new script and append it to </body> this should work just fine.

    /*if(this.actionMode==='edit'){
      console.log('edit  test  '+this.props.NdiFields.id);
      if(this.props.NdiFields.id===undefined){
        //means some refresh happens  id
        const idx = this.props.match.params.id;
        //getNdiFields(idx);
        Promise.resolve(this.props.getNdiFields(idx))
        .then((response)=>{
          this.setState(response.NdiFieldsOne);
          return response;
        });
      }
    }*/

    //zafClient = ZAFClient.init();

    console.log("MiniBoardzendesk...", zafClient );
    const locurl=window.location.href;
    console.log("TEEEEEEEEEST URL zafClient  ", zafClient, locurl);
    console.log("444MUUUUST weeeeeeeeeeee zeeeeendesk "+locurl);

    /*if(locurl.indexOf("?origin=")> -1 && locurl.indexOf("xqNeO=1")=== -1){
      const index  = locurl.indexOf('?origin=');
      const strOut =locurl.substr(index);
      const urlParamZendesk=strOut+"&xqNeO=1";
      const urlParamZendesk2=locurl+"&xqNeO=1";
      //localStorage.setItem("zendeskzat", strOut);
      console.log("MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk);
      //history.push(`./${urlParamZendesk}`);
      //history.replace(urlParamZendesk2);

      //history.push(`/zendesk/${urlParamZendesk2}`);
      history.push(`/zendesk/${urlParamZendesk}`);
      history.go();
    }*/

    if(this.actionMode==='ticket'){
      //const idx = this.props.match.params.id;
      console.log("MiniBoardzendesk weeeeeeeeeeeeeeeeeeee", this.backTicketId);
      if(this.backTicketId >0){
        this.setState({ ["realticket_id"]: this.backTicketId });
        console.log("MiniBoardzendesk weeeeeeeeeeeeeeeeeeee yoooooupi", this.backTicketId);
      }
    }


    //getTicketSummary
    const ticket_id = this.state.realticket_id; // 197504
	if(ticket_id!=="" || this.backTicketId >0){
		const objToSend={id:0, ticketId:ticket_id}; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
		if(this.backTicketId >0){
			objToSend.ticketId=this.backTicketId;
    }
    stepRunned++;
		console.log("MiniBoardzendesk weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend);
    this.loadTicketSummary(objToSend);

		/*
		Promise.resolve(this.props.getProductFields(objToSend))
		.then((response)=>{
			//this.setState(response.NdiFieldsOne);
			//console.log("HOHOHO response from server .... ");  //console.log(response.NdiFieldsOne.data);
			//this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
			this.loadTicketSummary(objToSend);
			return response;
		});*/
	}

	//this.loadProductsFields(objToSend);

	// //console.log("zeeeeeeeeeeeeeendesk client zaf");

	if(zafClient!==undefined && zafClient!==false){
    const this00=this;  let neoId=0;
    //let stepRunned=0;
    zafClient.invoke('resize', { width: '100%', height: '600px' });

    console.log("######MiniBoardzendesk yeeeeeeeeeees registered the ticket");
    zafClient.get('ticket.requester.name').then(function(data) {
      console.log(data); // { "ticket.requester.name": "Mikkel Svane" }
    });
    zafClient.get('ticket.requester.externalId').then(function(data) {
      console.log("####act MiniBoardzendesk externalId ");
      console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
    });



		zafClient.on('app.registered', function(e) {
			// do stuff
      console.log("MiniBoardzendesk yeeeeeeeeeees registered the ticket");
      //ticket.requester.id_client_neolegal
      // zafClient.get('ticket.requester.externalId').then(function(data) {
      //   console.log("reg MiniBoardzendesk externalId ");
      //   console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
      // });
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				//this.showToastError(response.ticket);
				console.log("MiniBoardzendesk uuuuuuu####inside if zeeeeeendesk client zaf   goooooooooood "+response.ticket.id);
				console.log(response.ticket);
        console.log("MiniBoardzendesk inside if zeeeeeeeeeeeeeendesk client zaf");
        console.log("IMP FIELDS: ", response.ticket.requester.email);
        stepRunned++;
        //const objToSend={id:0, ticketId:response.ticket.id};
        const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
				this00.loadTicketSummary(objToSend);
			}, (response) => {
				//console.log(`Error from zafClient ticket status: ${response.status}`)
			});
		});

		zafClient.on('app.activated', function(e) {
			// do stuff
      console.log("MiniBoardzendesk activated oooooooooooooooh an agent navigates back to a ticket");

      // zafClient.get('ticket.requester.externalId').then(function(data) {
      //   console.log("act MiniBoardzendesk externalId ");
      //   console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
      // });
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				//this.showToastError(response.ticket);
				console.log("MiniBoardzendesk uuuuuuu####inside if zeeeeeendesk client zaf   goooooooooood "+response.ticket.id);
				console.log(response.ticket);
        console.log("MiniBoardzendesk inside if zeeeeeeeeeeeeeendesk client zaf");
        console.log("IMP FIELDS: ", response.ticket.requester.email);
        stepRunned++;
				const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
        this00.loadTicketSummary(objToSend);

			}, (response) => {
				//console.log(`Error from zafClient ticket status: ${response.status}`)
			});
    });

    console.log("MiniBoardzendesk  oooooooooooooooh last on Component-ready... "+stepRunned);

    if(stepRunned===0){
			console.log("MAINSCREEEN inside if zeeeeeeeeeeeeeendesk client zafGOOOOOO");
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				//this.showToastError(response.ticket);
				console.log("MiniBoardzendesk uuuuuuu####inside if zeeeeeendesk client zaf   goooooooooood "+response.ticket.id);
				console.log(response.ticket);
        console.log("MiniBoardzendesk inside if zeeeeeeeeeeeeeendesk client zaf");
        console.log("IMP FIELDS: ", response.ticket.requester.email);
        stepRunned++;
				const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
        this00.loadTicketSummary(objToSend);

			}, (response) => {
				//console.log(`Error from zafClient ticket status: ${response.status}`)
			});
		}

    console.log("inside if zeeeeeeeeeeeeeendesk GET ID CLIENT NEOLEGAL ");

    // zafClient.get('id_client_neolegal').then(function(data) {
    //   console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
    // });

    zafClient.on('*.changed', function(e) {
			const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
			console.log("ZENDESK CHANGED*** ", propertyName, newValue);
			console.log(e);
		});

		/*zafClient.on('*.changed', function(e) {
			const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
			console.log("ZENDESK CHANGED*** ", propertyName, newValue);
			console.log(e);
		});

		zafClient.on('ticket.submit.done', function(e) {
			// handler code
			// const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			// const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
			console.log("ZENDESK ticket.submit.done*** ");
			//console.log(e);
			//this00.saveUpdateTicketData(null); //from left to right not allowed any more
		});*/
  }

  //stepRunned++;
  /*if(stepRunned===0){
    //const locurl=window.location.href;
    let goUrl="";
    const patt=/(\/admin\/)/;
    console.log("LOOOOOKING FOR PATH admin? "+patt.test(locurl));
    if(patt.test(locurl)){
		  goUrl= '/admin/';
	  }else{
      goUrl= '/zendesk/';
    }
    console.log("MiniBoardzendesk last on Component-ready... we goooooooo refreshing "+stepRunned+" url="+locurl);
    //this.props.history.push(goUrl);
    history.push(goUrl);
  }*/


  }

  componentReloadData = () => {
    let stepRunned=0;
    // const script = document.createElement("script");
		// script.src = `https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js`;
		// script.async = true;
		// document.body.appendChild(script);
    // document.body.removeChild(script); // remove it so in each time you visit this route it won't create new script and append it to </body> this should work just fine.

    /*if(this.actionMode==='edit'){
      console.log('edit  test  '+this.props.NdiFields.id);
      if(this.props.NdiFields.id===undefined){
        //means some refresh happens  id
        const idx = this.props.match.params.id;
        //getNdiFields(idx);
        Promise.resolve(this.props.getNdiFields(idx))
        .then((response)=>{
          this.setState(response.NdiFieldsOne);
          return response;
        });
      }
    }*/

    //zafClient = ZAFClient.init();

    console.log("MiniBoardzendesk...", zafClient );
    const locurl=window.location.href;
    console.log("TEEEEEEEEEST URL zafClient  ", zafClient, locurl);
    console.log("444MUUUUST weeeeeeeeeeee zeeeeendesk "+locurl);

    /*if(locurl.indexOf("?origin=")> -1 && locurl.indexOf("xqNeO=1")=== -1){
      const index  = locurl.indexOf('?origin=');
      const strOut =locurl.substr(index);
      const urlParamZendesk=strOut+"&xqNeO=1";
      const urlParamZendesk2=locurl+"&xqNeO=1";
      //localStorage.setItem("zendeskzat", strOut);
      console.log("MUUUUST weeeeeeeeeeee zeeeeendesk "+urlParamZendesk);
      //history.push(`./${urlParamZendesk}`);
      //history.replace(urlParamZendesk2);

      //history.push(`/zendesk/${urlParamZendesk2}`);
      history.push(`/zendesk/${urlParamZendesk}`);
      history.go();
    }*/

    if(this.actionMode==='ticket'){
      //const idx = this.props.match.params.id;
      console.log("MiniBoardzendesk weeeeeeeeeeeeeeeeeeee", this.backTicketId);
      if(this.backTicketId >0){
        this.setState({ ["realticket_id"]: this.backTicketId });
        console.log("MiniBoardzendesk weeeeeeeeeeeeeeeeeeee yoooooupi", this.backTicketId);
      }
    }


    //getTicketSummary
    const ticket_id = this.state.realticket_id; // 197504
	if(ticket_id!=="" || this.backTicketId >0){
		const objToSend={id:0, ticketId:ticket_id}; //{id:4, ticketId:""}; CASE ID: 369 PROD ID: 4
		if(this.backTicketId >0){
			objToSend.ticketId=this.backTicketId;
    }

    //const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
		if(this.neoemail!==""){
      objToSend.email=this.neoemail;
    }

    //stepRunned++;
		console.log("MiniBoardzendesk weeeeeeeeeeeeeeeeeeeere goooing to server ayayay ", objToSend);
    this.loadTicketSummary(objToSend);

	}

	//this.loadProductsFields(objToSend);

	// //console.log("zeeeeeeeeeeeeeendesk client zaf");

	if(zafClient!==undefined && zafClient!==false){
    const this00=this;  let neoId=0;
    this.clientOnZendeskSCR=10;
    //let stepRunned=0;
    zafClient.invoke('resize', { width: '100%', height: '600px' });

    console.log("######MiniBoardzendesk yeeeeeeeeeees registered the ticket");
    zafClient.get('ticket.requester.name').then(function(data) {
      console.log(data); // { "ticket.requester.name": "Mikkel Svane" }
    });
    zafClient.get('ticket.requester.externalId').then(function(data) {
      console.log("####act MiniBoardzendesk externalId ");
      console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
    });



		zafClient.on('app.registered', function(e) {
      // do stuff
      this00.clientOnZendeskSCR=11;
      console.log("MiniBoardzendesk yeeeeeeeeeees registered the ticket");
      //ticket.requester.id_client_neolegal
      // zafClient.get('ticket.requester.externalId').then(function(data) {
      //   console.log("reg MiniBoardzendesk externalId ");
      //   console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
      // });
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				//this.showToastError(response.ticket);
				console.log("MiniBoardzendesk uuuuuuu####inside if zeeeeeendesk client zaf   goooooooooood "+response.ticket.id);
				console.log(response.ticket);
        console.log("MiniBoardzendesk inside if zeeeeeeeeeeeeeendesk client zaf");
        console.log("IMP FIELDS: ", response.ticket.requester.email);
        stepRunned++;
        //const objToSend={id:0, ticketId:response.ticket.id};
        this00.neoemail=response.ticket.requester.email;
        const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
				this00.loadTicketSummary(objToSend);
			}, (response) => {
				//console.log(`Error from zafClient ticket status: ${response.status}`)
			});
		});

		zafClient.on('app.activated', function(e) {
      // do stuff
      this00.clientOnZendeskSCR=12;
      console.log("MiniBoardzendesk activated oooooooooooooooh an agent navigates back to a ticket");

      // zafClient.get('ticket.requester.externalId').then(function(data) {
      //   console.log("act MiniBoardzendesk externalId ");
      //   console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
      // });
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				//this.showToastError(response.ticket);
				console.log("MiniBoardzendesk uuuuuuu####inside if zeeeeeendesk client zaf   goooooooooood "+response.ticket.id);
				console.log(response.ticket);
        console.log("MiniBoardzendesk inside if zeeeeeeeeeeeeeendesk client zaf");
        console.log("IMP FIELDS: ", response.ticket.requester.email);
        stepRunned++;
        this00.neoemail=response.ticket.requester.email;
				const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
        this00.loadTicketSummary(objToSend);

			}, (response) => {
				//console.log(`Error from zafClient ticket status: ${response.status}`)
			});
    });

    console.log("MiniBoardzendesk  oooooooooooooooh last on Component-ready... "+stepRunned);

    if(stepRunned===0){
      console.log("MAINSCREEEN inside if zeeeeeeeeeeeeeendesk client zafGOOOOOO");
      this00.clientOnZendeskSCR=13;
			zafClient.get('ticket').then((response) => {
				const subject = response.ticket.subject
				this00.setState({ticket: {subject}, realticket_id:response.ticket.id, fullticket: response.ticket});
				//this.showToastError(response.ticket);
				console.log("MiniBoardzendesk uuuuuuu####inside if zeeeeeendesk client zaf   goooooooooood "+response.ticket.id);
				console.log(response.ticket);
        console.log("MiniBoardzendesk inside if zeeeeeeeeeeeeeendesk client zaf");
        console.log("IMP FIELDS: ", response.ticket.requester.email);
        stepRunned++;
        this00.neoemail=response.ticket.requester.email;
				const objToSend={id:0, ticketId:response.ticket.id, neoid:0, email:response.ticket.requester.email};
        this00.loadTicketSummary(objToSend);

			}, (response) => {
				//console.log(`Error from zafClient ticket status: ${response.status}`)
			});
		}

    console.log("inside if zeeeeeeeeeeeeeendesk GET ID CLIENT NEOLEGAL ");

    // zafClient.get('id_client_neolegal').then(function(data) {
    //   console.log(data); // { 'ticket.subject': 'Help, my printer is on fire' }
    // });

    zafClient.on('*.changed', function(e) {
			const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
      const totalt=new Date().getTime();
			const lastTimeAction=sessionStorage.getItem("lasttaction");
			const blockfrmzendtoneo=sessionStorage.getItem("blockfrmzendtoneo");

      // if(blockfrmzendtoneo!==undefined && blockfrmzendtoneo >0){
      //   console.log("MiniBoard WEEEEE BLOCK *.changed *** ");
      //   return false;
      // }

      // if(this00.activeTab!==0){
      //   this00.activeTab=0;
      //   this00.setState({["activeTab"]:0,});
      // }
      if(this00.activeTab!==0){
        console.log("MiniBoard but wont ZENDESK CHANGED*** ", propertyName, newValue);
        return false;
      }
      if(this00.zendeskChangeLock!==true){
        this00.setZendekChangeTacker (propertyName, newValue);
        console.log("ZENDESK CHANGED*** ", propertyName, newValue);
      }else{
        console.log("IGNORED ZENDESK CHANGED*** ", propertyName, newValue);
      }
			//console.log(e);
		});

    zafClient.on('ticket.submit.done', function(e) {
			// handler code
			// const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			// const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
      const totalt=new Date().getTime();
			const lastTimeAction=sessionStorage.getItem("lasttaction");
			const blockfrmzendtoneo=sessionStorage.getItem("blockfrmzendtoneo");
      // if(blockfrmzendtoneo!==undefined && blockfrmzendtoneo >0){
      //   console.log("MiniBoard WEEEEE BLOCK submit.done *** ");
      //   return false;
      // }

      if(this00.isZendekBusy){
				console.log("Mini ZENDESK submit.done BUUUUUUUSY NO ACTION *** ");
				return false;
			}
      // if(this00.activeTab!==0){
      //   this00.activeTab=0;
      //   this00.setState({["activeTab"]:0,});
      // }
      if(this00.activeTab!==0){
        console.log("MiniBoardzendesk but wont ZENDESK ticket.submit.done*** ");
        return false;
      }
      this00.zendeskChangeLock=true;
			console.log("MiniBoardzendesk ZENDESK ticket.submit.done*** ");
      this00.saveUpdateTicketDataZendeskToNeo(e);
			//console.log(e);
			//this00.saveUpdateTicketData(null); //from left to right not allowed any more
		});

		/*zafClient.on('*.changed', function(e) {
			const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
			console.log("ZENDESK CHANGED*** ", propertyName, newValue);
			console.log(e);
		});

		zafClient.on('ticket.submit.done', function(e) {
			// handler code
			// const propertyName = e.propertyName;   // "ticket.assignee.user.email"
			// const newValue = e.newValue;           // "sally.agent.1@example.org"
			//handleChange(propertyName, newValue);
			console.log("ZENDESK ticket.submit.done*** ");
			//console.log(e);
			//this00.saveUpdateTicketData(null); //from left to right not allowed any more
		});*/
  }

  //stepRunned++;
  /*if(stepRunned===0){
    //const locurl=window.location.href;
    let goUrl="";
    const patt=/(\/admin\/)/;
    console.log("LOOOOOKING FOR PATH admin? "+patt.test(locurl));
    if(patt.test(locurl)){
		  goUrl= '/admin/';
	  }else{
      goUrl= '/zendesk/';
    }
    console.log("MiniBoardzendesk last on Component-ready... we goooooooo refreshing "+stepRunned+" url="+locurl);
    //this.props.history.push(goUrl);
    history.push(goUrl);
  }*/


  }


  loadTicketSummary = (objToSend) => {
    this.setState({ ["isLoaderOpen"]: true});
    const this2=this;
    Promise.resolve(this.props.getTicketSummary(objToSend))
    .then((response)=>{
      //this.setState(response.NdiFieldsOne);
      const responseInfo=response.NdiFieldsOne.data;

      /*
      const clientProdTransInfo=response.NdiFieldsOne.data.clientProdTransInfo;
      const isClientId=clientProdTransInfo.client.id;
      const isClientLead=isClientId >0?false:true;
      //console.log("..HOHOHO response from server .... ");  //console.log(response.NdiFieldsOne.data);
      this.handleDynamicFormGenerator(response.NdiFieldsOne.data);
      this.setState({["dynamicFormAllData"]: response.NdiFieldsOne.data, ["isClientLead"]: isClientLead });
      //console.log("HOHOHO just assinged to dynamicFormAllData .... ");
      this.setState({ ["isLoaderOpen"]: false});
      this.showToast("On a chargé les données avec succès!", "success");
      // this.saveUpdateTicketData(null);
      setTimeout(() => {
        this.showToast("On va comparer les données de gauche à droite.", "warning");
        this.saveUpdateTicketData(null);
      }, 10000);  */

      //const clientaddress=

      this.setState({["ticketSummary"]: responseInfo, ["client_type"]: responseInfo.client_type,
      ["isLoaderOpen"]: false, ["clientAddress"]:responseInfo.address,  });

      console.log("..HOHOHO response from server .... loadTicketSummary ");  console.log(response);


      return response;
    }).catch(function(error){
      this2.setState({ ["isLoaderOpen"]: false});
      alert(error);
    });

  }


  setZendekChangeTacker = (fieldName, fieldValue) =>{
    let fieldNamex=fieldName;
    if(!this.zendlistElementChanges.includes(fieldNamex)){
      this.zendlistElementChanges.push(fieldNamex);
      this.zendlistElementChangesval.push(fieldValue);
      //this.listElementChanges2.push(indexField);
      this.setState({ ["zendchangeCounter"]: this.zendlistElementChanges.length});
    }else{
      const index=this.zendlistElementChanges.indexOf(fieldNamex);
      if(index>-1){
        this.zendlistElementChangesval[index]=fieldValue;
      }
    }
    console.log("ZENDEK-MAP-CHANGED ",this.zendlistElementChanges, this.zendlistElementChangesval);


  }


  saveUpdateTicketDataZendeskToNeo = (event) => {
    //console.log("ZENDESK to NEO -- teeeeeeeeeeest handleTestData client zaf saveUpdateTicketData ");
    const this00=this;
    const realticket_id=this.state.realticket_id;
    const { t } = this.props;
    const idNeoId=this.state.neoid; // 0;
    const ofilist=this00.zendlistElementChanges;
    if(zafClient!==undefined && zafClient!==false){
      //zafClient.invoke('resize', { width: '100%', height: '600px' });
      //this.setState({ ["isLoaderOpen"]: true});
      this.isZendekBusy=true;
      this.setState({ ["isLoaderOpen"]: true});
      //this.showToast("On va comparer les données de zendesk de gauche à droite.", "warning");

      zafClient.get('ticketFields').then(function(data) {

        // Get all fields to query against
        let fields = data["ticketFields"];
        let CustomFieldName; let fieldsNeeded=[];
        const avoidList=["collaborator", ""];

        // Iterate through each field
        for (let field in fields) {
          const klx="ticket."+fields[field].name;
          if(!ofilist.includes(klx)){
            continue;
          }
          //fieldsNeeded.push(fields[field].name); ticket.custom_field_360019452754 vs custom_field_360003324874
          if((fields[field].name).indexOf("custom_field_")> -1){
            fieldsNeeded.push("ticket.customField:"+fields[field].name);
          }else{
            fieldsNeeded.push("ticket."+fields[field].name);
          }
          console.log("zzFIELD ", field, fields[field].name, fields[field].label);
        }

        console.log("OFILIST ", ofilist);

        /*for (let m=0;  m<ofilist.length; m++) {
          const field=ofilist[m];
          // if(!this.zendlistElementChanges.includes(fieldNamex)){
          //   continue;
          // }
          //fieldsNeeded.push(fields[field].name); ticket.custom_field_360019452754
          if((""+field).indexOf("ticket.custom_field_")> -1){
            fieldsNeeded.push("ticket.customField:"+(""+field).replace("ticket.custom_field_", ""));
          }else{
            fieldsNeeded.push("ticket."+fields[field].name);
          }

          //console.log("qqqFIELD ", field, fields[field].name, fields[field].label);
          console.log("qqqFIELD ", field, fieldsNeeded);
        }*/



         console.log("xFIELDs ", fieldsNeeded);
         console.log("DATA FOR FIELDs ");
         //return false;

        zafClient.get(fieldsNeeded).then(function(data){

          // const fieldValue= Object.keys(data)[1];
          // console.log( data[fieldValue]);
          let myNdiTicketDataLeftList=[];

          // console.log(data);
           //console.log("single block data ");
          //const datax=JSON.parse(JSON.stringify(data));
          for (let fieldOne of fieldsNeeded){
            // const fieldValue= Object.keys(fieldData)[1];
            // console.log( data[fieldValue]);
            // if(!this.zendlistElementChanges.includes(fieldNamex)){
            //   continue;
            // }
            let trackStrOrDates= data[fieldOne];
            //Wed Dec 09 2020 19:00:00 GMT-0500 (heure normale de l’Est nord-américain)
            console.log("qqq=:==>", fieldOne, data[fieldOne]);
            if(data[fieldOne]!==null && data[fieldOne]!==undefined){
              const idDBField=null; const zendeskField=fieldOne;
              let tickeId=0, caseid=0, productfieldid=0, fieldid=0;
              let isRequired=false;
              let fieldDataval=""; let isError=false;  let fieldDataRawval="";

              if(trackStrOrDates!==null && (""+trackStrOrDates).indexOf(" (heure normale de l’Est nord-américain)")> -1){
                trackStrOrDates=trackStrOrDates.replace(" (heure normale de l’Est nord-américain)", "");
                //trackStrOrDates=Date.parse(trackStrOrDates).toISOString();
                console.log("WE USE THIS ---BF- ", trackStrOrDates);
                let event = new Date(trackStrOrDates);
                //console.log("toString", event.toString()); console.log("toISOString", event.toISOString());
                const isod=(event.toISOString()).split("T");
                console.log("##isod",  isod[0]);
                event = new Date(isod[0]);
                //console.log("##toString", event.toString()); console.log("##toISOString", event.toISOString());
                //console.log(event.toLocaleString('en-US', { timeZone: 'UTC' }));
                //console.log(event.format('yyyy-mm-dd HH:MM:ss'));
                //console.log(dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false)); //dateFormat(this, mask, utc);
                //data[fieldOne]= dateFormat(event, 'yyyy-mm-dd HH:MM:ss', false);

                //data[fieldOne]= dateFormat(event, 'yyyy-mm-dd', false);
                data[fieldOne]= isod[0];
                //console.log("WE USE THIS D ", dateFormat(event, 'yyyy-mm-dd', false));

              }

              console.log("TRUUUUUUUUUUUUUUUUUUTH ", idDBField, zendeskField);
              console.log("=:===>", fieldOne, data[fieldOne], trackStrOrDates);

              const blockDataForField=this00.getFullObjectField (idDBField, zendeskField);
              console.log("TRUUUUUUUUUUUUUUUUUUTH2 ", blockDataForField);
              if(blockDataForField!==null){
                const fieldBlock=blockDataForField.fieldBlock;
                const productField=blockDataForField.productFields;
                const FieldData=blockDataForField.FieldData;
                const ticketDataLeft=FieldData.ticketDataLeft;
                let fieldDataval=""; let isError=false; let field_data_raw="";
                const indexField=FieldData.index;
                let elemVal=""; let field_data_rawx="";
                const fieldname=fieldBlock.name;
                if(productField.id >0){ productfieldid=productField.id; }
                if(productField.field_id >0){ fieldid=productField.field_id; }
                if(productField.required >0){ isRequired=true; isError=true;}
                if(FieldData.case_id >0){ caseid=FieldData.case_id; }
                console.log("test x=:==>", fieldid, fieldname);
                if(ticketDataLeft!==null && ticketDataLeft.id >0){
                  tickeId=ticketDataLeft.id; fieldDataval=ticketDataLeft.field_data;
                  // if(fieldDataval!==""){
                  // 	isError=false;
                  // }
                  console.log("goUpdate...y=:==>", fieldid, fieldname, ticketDataLeft.id);
                  let isEmpty=true;
                  if(ticketDataLeft.field_data_raw!==null && ticketDataLeft.field_data_raw!==""){
                    field_data_rawx=ticketDataLeft.field_data_raw;  isEmpty=false;
                    const datRaw=JSON.parse(ticketDataLeft.field_data_raw);
                    if("["+data[fieldOne]+"]"===ticketDataLeft.field_data_raw || data[fieldOne]===datRaw[0]){
                      console.log("MINI field_data_raw is EQUAL nooUpdate...y=:==>", fieldid, fieldname, ticketDataLeft.id);
                      continue;
                    }
                  }
                  if(fieldDataval!=="" && fieldDataval===data[fieldOne]){
                    console.log("field_data is EQUAL nooUpdate2...y=:==>", fieldid, fieldname, ticketDataLeft.id);
                    continue;
                  }
                  if(fieldDataval!==""){
                    isError=false;  isEmpty=false;
                    elemVal=fieldDataval;
                  }
                  //if(isEmpty){
                    if(data[fieldOne].length> 245){
                      field_data_rawx=[data[fieldOne]];
                    }else{
                      elemVal=data[fieldOne];
                    }
                    if(fieldBlock.field_type==="textarea"){
                      field_data_rawx=[data[fieldOne]];
                    }
                  const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid,
                    field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId,
                    fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id };
                    myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);
                  //}
                }else{
                  //for insert
                  console.log("z=:==>", fieldid, fieldname);
                  if(data[fieldOne].length> 245){
                    field_data_rawx=[data[fieldOne]];
                  }else{
                    elemVal=data[fieldOne];
                  }
                  const myNdiTicketDataLeftx = {id: tickeId, product_field_id: productfieldid, case_id: caseid,
                    field_data: elemVal, field_data_raw: field_data_rawx, neoid:idNeoId,
                    fieldname:fieldname, fieldid:fieldid, realticket_id:realticket_id };
                    myNdiTicketDataLeftList.push(myNdiTicketDataLeftx);
                }

              }
            }
          }

          if(myNdiTicketDataLeftList.length> 0){
            console.log("zendesk-to-neo DATAO SERVER"); console.log(myNdiTicketDataLeftList);
            //const this00=this;
            //this00.handleResetStatesXLoad(event); //********************HEADACH CENTER
            const clientOnZendeskSCR=this00.clientOnZendeskSCR;
            const myNdiTicketDataLeft={massFZENDESK:1, ZENDESKIgnoreAPI:1, massFields:myNdiTicketDataLeftList, realticket_id:realticket_id, clientOnZendeskSCR:clientOnZendeskSCR};

      this00.setState({ ["isLoaderOpen"]: true});
      const ticketSummary=this00.state.ticketSummary;
      //this00.showToast("Maintenant on va actualiser les données de zendesk de gauche à droite.", "warning");
    Promise.resolve(this00.props.addOrUpdateTicketData(myNdiTicketDataLeft))
    .then((response)=>{
      //this.setState(response.NdiFieldsOne);
      //const resp=response.NdiFields;
      console.log("check solving refresh... ", );

      //this00.handleDynamicFormGenerator(response.NdiFields);
      //this00.setState({ ["isLoaderOpen"]: false, ["dynamicFormAllData"]: response.NdiFields, });  //response.NdiFields
      ticketSummary.zendeskduplex=response.NdiFields;
      //this00.setState({ ["isLoaderOpen"]: false, ["ticketSummary"]: ticketSummary, });
      this00.setState({ ["ticketSummary"]: ticketSummary, });
      this00.zendeskChangeLock=false;
      this00.zendlistElementChanges=[];
      this00.zendlistElementChangesval=[];
      console.log("QQQQQQQQQQ  REDUCE dynamicFormAllData .... ", response);

      setTimeout(() => {
        this00.isZendekBusy=true;
        this00.setState({["isLoaderOpen"]: false, });
        console.log("Mini lasttaction release isZendekBusy");
        }, 5000);

      return response;
    }).catch(function(error){
      const errObj=error;
      this00.setState({ ["isLoaderOpen"]: false});
      this00.isZendekBusy=false;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n There was some error from the expected data from the server. Please try again later.";
      }
      else if(errObj.status===500){
        //errMessage +="\n There was some error from the expected data from the server. Please try again later.";
        //errMessage +="\n 9999999 ".errObj.data.error.description;
        //errMessage +="\n 9999999 ".errObj.error.description;
        errMessage ="SERVER ERROR INTERNAL or file not found.";
        }
      //this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});

      //this00.showToastError(errMessage);
      //alert(errMessage); //error
      });
          }else{
            //this00.showToast(t("zqwNFFtxt12_x"), "warning");
            this00.setState({ ["isLoaderOpen"]: false});
            this00.zendeskChangeLock=false;
            this00.isZendekBusy=false;
          }


        });


      });


    }
  }

  getFullObjectField = (idDBField, zendeskField) => {
    //const fullObj=this.state.dynamicFormAllData;
    const comThis=this.commonArrays;
    const fullObj=this.state.ticketSummary.zendeskduplex;
    const products=fullObj.products;
    const productFields=fullObj.productFields;
    const fields=fullObj.fields;
    const fieldOptions=fullObj.fieldOptions;
    const fieldsIdsopt=fullObj.fieldsIdsopt;
    const fieldServices=fullObj.fieldServices;
    //const case_id=fullObj.case_id;
    const ticketDataLeft=fullObj.optionsData;
    const ticketProds=fullObj.ticketProds; //folders_products
    let ticketDataLeftRec=null;
    const realticket_id=fullObj.ticket_id;
    const clientProdTransInfo=fullObj.clientProdTransInfo;
    //const product=fullObj.product;
    //const product=fullObj.product;
    let fieldList = []; let text="";
    let dynamicForm = []; let dynamicFormData = [];
    const typeListx = ["select","checkbox","radio"];
    let selectedFiles=[]; let valueNextStep=[];

    if(productFields===undefined){
      return null;
    }

    let blockElemData=null;

    console.log("QAAAAAAAAAAA", idDBField, zendeskField);
    //this.setState({ ["valueNextStep"]: valueNextStep });
    const field_idx=zendeskField!==null?zendeskField:idDBField;
    let fieldBlock=comThis.getFieldRecordx(fields, field_idx);
    console.log("xQAAAAAAAAAAA", fieldBlock);
    let promosListx = productFields.length > 0
        && productFields.map((item, i) => {
          if(fieldBlock!==null && item.field_id===fieldBlock.id){

      //let fieldBlock=this.getFieldRecord(fields, item.field_id);
      //const x = this.getFieldTypeId(fieldBlock.field_type);
      ticketDataLeftRec=comThis.getTicketDataRecord(ticketDataLeft, item.id);
      let FieldData=null;
      let product=comThis.getProductRecord(products, item.product_type_id);
      const prodId=product.name;
      let ticketProd=comThis.getTicketProductRecord(ticketProds, prodId);
      if(ticketProd===null || ticketProd===undefined){ ticketProd={id:0}; }
      const case_id=ticketProd.id;

      FieldData={fieldOptions:null, fieldsIdsopt:fieldsIdsopt, fieldServices:fieldServices,
        case_id:case_id, ticketDataLeft:ticketDataLeftRec, index:i};

      //console.log("QQQQQQQQQQQ1111", item.field_id);

        //fieldBlock, item, FieldData
        //blockElem = this.genTextField(fieldBlock, item, FieldData);
        blockElemData= {fieldBlock:fieldBlock, productFields:item, FieldData:FieldData};
        //return {fieldBlock:fieldBlock, productFields:item, FieldData:FieldData};
        console.log("blockElemData", blockElemData);
        return (
          null
          );
      }

        return (
          null
        );
    }, this);

    ///////////////////////////////////////////////////////////////


    //this.setState({ ["valueClientSearch"]: (newValue.firstname+' '+newValue.lastname+' '+newValue.email)});
      //ZZZZthis.setState({ ["clientInfo"]: newValue})zzzz; productId   transactionId

    // const dynamicForm=this.state.dynamicForm;
    // //console.log("RESEULT FRM ", dynamicForm);

    return blockElemData;

    }





  handleSubmit = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }

	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true });
		  }
	  }
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){
		this.props.addOrUpdateNdiFields(NdiFields, 1);
		//this.props.addNdiFields(NdiFields);
	}else{
		this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);

	}
  };

  handleCancel = () => {
    if(this.actionMode==='new'){
      this.props.history.push(`/NdiFields`);
    }else{
      const idx = this.props.match.params.id;
      this.props.history.push(`/NdiFields/${idx}`);
      //this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
    }
  }

  render() {
    const { t } = this.props;
    const myToastMessage=this.state.toastrMessage;
  	const toastrType=this.state.toastrType;
    const ShowAllTabsExtraInfo=this.ShowAllTabsExtraInfo;

    return (
      <div>
		<ShowAllTabsExtraInfo proptest={"neo"} />
    <Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
      </div>
    );
  }


  //neoSearchData
  handleCloseNeoSearch  = (anyEvent) => {
    console.log("OLD STYLE SUCCESS handleCloseNeoSearch ");
    this.setState({["openNeoSearch"]: false });
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);

  }

  handleOpenNeoSearch  = (anyEvent) => {
    this.setState({["openNeoSearch"]: true });
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);
    console.log("OLD STYLE SUCCESS handleOpenNeoSearch ");

  }

  NeoSearchResultHistory = (props) => {
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const myLang=t("curlang");
    const openNeoSearch=this.state.openNeoSearch;
    if(!openNeoSearch){
      return (null);
    }

    //const neoSearchData=this.state.neoSearchData;
    const neoSearch=this.state.ticketSummary.neoSearch;
    if(neoSearch!==undefined && neoSearch!==null && neoSearch.length >0){}else{
      return (null);
    }

    console.log("neoSearchneoSearchneoSearchneoSearch ", neoSearch);


    //return (<NdiInterestConflict neoSearchData={neoSearch}  />);
    return (
        <div>
          <Dialog maxWidth={"sm"} open={openNeoSearch} onClose={this.handleCloseNeoSearch} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
            <DialogContent id="form-dialog-confirm">
              <NdiInterestConflict neoSearchData={neoSearch}  />

                <div className="form-group col-md-9">
                <br/>
                <input type="button" onClick={this.handleCloseNeoSearch} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;

                </div>
            </DialogContent>

          </Dialog>
        </div>
    );

    // if(myLang==="fr"){
    //   return this.xHelpInfoAsOnTheSiteFr(event);
    // }else{
    //   return this.xHelpInfoAsOnTheSiteEn(event);
    // }
  }

  getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }



 ShowAllTabsExtraInfo = (event) => {
  const { t } = this.props;
  //const classes = useStyles();
  //const [value, setValue] = React.useState(0);
  const value=this.state.activeTab;
  //const this00=this;


  const handleChange = (event, newValue) => {
    console.log("WOOOOOOOOOOOOOOOOOOOOY TAB CHANGED ", newValue);
    this.activeTab=newValue;
    this.setState({["activeTab"]:newValue,});
    //setValue(newValue);
    if(newValue===0){
      this.componentReloadData();
    }
  };



const productInfo=this.state.infoProduct;

let productOptions = [];
let descriptionLiList = [];
let infoProduct_title="";
let infoProduct_excerpt="";




    const TabPanel = this.TabPanel;
    const lang="fr"; //this.state.language;
    //const whyNeolegal=lang==="en"? "Why Neolegal?":"Pourquoi Neolegal ?";
	  const whyNeolegal="Other 2";
    const showProdMulti=false; //this.state.showProdMulti;

  //ticketSummary client_type
  const ticketSummary=this.state.ticketSummary;
  const client_type=this.state.client_type;
  let statusFile="";    let sourceClient="";    let nopolice="";
  let language=t("zqwTSumtxt12_x");   let region="";   let clientInfo= ""; // "";  [];
  let clientInfo2= ""; let clientInfo3= ""; let clientInfo4= "";  let clientInfo5= "";
  if(ticketSummary!==null && ticketSummary.client!==undefined){
    if(ticketSummary.client.language==="en"){
      language=t("zqwTSumtxt13_x");
    }else if(ticketSummary.client.language==="fr"){
      language=t("zqwTSumtxt12_x");
    }

  }

  let clientInfox= ""; let clientInfo6x= ""; let clientInfo7x= "";  let clientInfo8x= "";
  let clientInfo2x= ""; let clientInfo3x= ""; let clientInfo4x= "";  let clientInfo5x= "";


  if(ticketSummary.client!==null && ticketSummary.client!==undefined){
    /*clientInfo +="Nom: "+ticketSummary.client.firstname+" <br />";
    clientInfo +="Prénom: "+ticketSummary.client.lastname+" <br />";
    clientInfo +="Courriel: "+ticketSummary.client.email+" <br />";*/
    //clientInfo +=""+ticketSummary.client+"";
    //clientInfo +=""+ticketSummary.client+"";
    //if(ticketSummary.client.firstname!==undefined){
      clientInfo +=t("zqwTSumtxt14_x")+": "+ticketSummary.client.lastname+" ";
      clientInfo2 +=t("zqwTSumtxt15_x")+": "+ticketSummary.client.firstname+" ";
      clientInfo3 +=t("zqwTSumtxt16_x")+": "+ticketSummary.client.email+" ";
      clientInfo5 +=t("zqwTSumtxt17_x")+": "+ticketSummary.client.cell+" ";
    //}

    // //if(ticketSummary.client.firstname!==undefined){
    //   clientInfo +="Nom: "+(ticketSummary.client.firstname===undefined)?"": ticketSummary.client.firstname+" ";
    //   clientInfo2 +="Prénom: "+(ticketSummary.client.lastname===undefined)?"": ticketSummary.client.lastname+" ";
    //   clientInfo3 +="Courriel: "+(ticketSummary.client.email===undefined)?"": ticketSummary.client.email+" ";
    //   clientInfo5 +="Téléphone: "+(ticketSummary.client.cell===undefined)?"": ticketSummary.client.cell+" ";
    // //}


    // clientInfo.push("Nom: "+ticketSummary.client.firstname+" \n\n");
    // clientInfo.push("Prénom: "+ticketSummary.client.lastname+"\n\n");
    // clientInfo.push("Courriel: "+ticketSummary.client.email+"\n");
  }

  if(ticketSummary!==null && ticketSummary.address!==undefined){
    const taxInfo=getProvinceTaxInfo(ticketSummary.address.province);
    region=""+taxInfo.name;
    //clientInfo +="Adresse: "+ticketSummary.address.address+" <br />";
    //clientInfo.push("Adresse: "+ticketSummary.address.address+" ");
    clientInfo4 +=t("zqwTSumtxt18_x")+": "+ticketSummary.address.address+" ";
  }

  let statutdo="";   let statutdon="";  let rendezvous=[];  let statusdonarList=[];
  let personType="0"; let clientSetting=null;
  let b2bContractName="";   let b2bCompanyName="";   let natureDossier="";
  if(ticketSummary!==null){
    //statutdo=""+ticketSummary.statusdo;
    statutdo=""+ticketSummary.lastproduct;

    //statutdon=""+ticketSummary.statusdon;
    //statutdon=""+ticketSummary.statusdonar[(ticketSummary.statusdonar.length-1)];
    //statutdon=""+ticketSummary.statusdonar[1];
    statutdon=""+ticketSummary.statusdonar;
    let statusdonar=null;
    const statusdList=ticketSummary.statusdonar2;
    if(statusdList!==undefined && statusdList!==null && statusdList.length >0){
      let statusdListx = statusdList.length > 0
        && statusdList.map((item, i) => {
          //transactionProdList.push(<p key={"pls"+i}>- {item.description}</p>);
          statusdonarList.push(<span  key={"spx"+i} className="rndtag">{item}</span>);
        return (
          null
        );
      }, this);
    }



    console.log("statusdonar", ticketSummary.statusdonar );
    b2bContractName=ticketSummary.b2bContractName;
    b2bCompanyName=ticketSummary.b2bCompanyName;
    natureDossier=ticketSummary.natureDossier;
    const clientB2B=ticketSummary.clientB2B;
    if(clientB2B!==undefined && clientB2B!==null){
      nopolice=clientB2B.id_external;
    }

    const rendezvousx=ticketSummary.rendezvous;
    if(rendezvousx!==undefined && rendezvousx!==null && rendezvousx.length >0){
      const lang = getLang();
      const momentLang = `${lang}-ca` ;
      let rendezvousListx = rendezvousx.length > 0
        && rendezvousx.map((item, i) => {
          rendezvous.push(
            <div key={"rdv-000"+i} className="rdv-container">
              <div className="rdv-line">
                  <EventIcon style={{color: "#0076cf"}} />
                  {moment(item?.event_start).locale(momentLang).format("LLL")} - {moment(item?.event_end).locale(momentLang).format("HH:mm")}
              </div>
              {item?.service_name && <div className="rdv-line">
                  <GavelIcon style={{color: "#0076cf"}} />
                  {item.service_name}
              </div>}
              <div className="rdv-line">
                  <PersonIcon style={{color: "#0076cf"}} />
                  {item.name}
              </div>
            </div>
          );
        return (null);
      }, this);
    }

    personType=ticketSummary.personType;
    clientSetting=ticketSummary.clientSetting;
    if(personType==="2"){
      clientInfox +=t("zqwTSCnyname_x")+": "+clientSetting.sumarryCnyInfo.company+" ";
      clientInfo2x +=t("zqwTSCnyneq_x")+": "+clientSetting.sumarryCnyInfo.legalnumber+" ";
      clientInfo3x +=t("zqwTSCnycity_x")+": "+clientSetting.sumarryCnyInfo.city+" ";
      clientInfo5x +=t("zqwTSumtxt17_x")+": "+clientSetting.sumarryCnyInfo.tel+" ";
      clientInfo6x +=t("zqwTSCnyext_x")+": "+clientSetting.sumarryCnyInfo.ext+" ";
      clientInfo4x +=t("zqwTSumtxt18_x")+": "+clientSetting.sumarryCnyInfo.address+" ";
      clientInfo7x +=t("zqwTSCnycp_x")+": "+clientSetting.sumarryCnyInfo.cp+" ";
      clientInfo8x +=t("zqwTSCnyprovince_x")+": "+clientSetting.sumarryCnyInfo.province+" ";


    }



  }

  let transactionProdList=[];
  /*if(ticketSummary.transactions!==null && ticketSummary.transactions!==undefined){
    const productsList=ticketSummary.transactions;
    let productList = productsList.length > 0
      && productsList.map((item, i) => {
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        transactionProdList.push(<p key={"pls"+i}>- {item.description}</p>);
        //}
      return (
        null
      );
    }, this);
  }*/

  if(ticketSummary.transactionsall!==null && ticketSummary.transactionsall!==undefined){
    const productsList=ticketSummary.transactionsall;
    let productList = productsList.length > 0
      && productsList.map((item, i) => {
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        transactionProdList.push(<p key={"pls"+i}>- {item.description+ ' ($'+item.amount+')'}</p>);
        //}
      return (
        null
      );
    }, this);
  }


  //const taxInfo=getProvinceTaxInfo("QC");
  //const Alert=this.Alert;
  //const anchorEl=this.state.anchorEl;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [userLogoutOpen, setUserLogoutOpen] = React.useState(false);
  //const isUserLogoutOpen= Boolean(anchorLeftMenu);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const ticket_id = this.state.realticket_id;
  const client_id = this.state.ticketSummary.client_id;


  let extraInfo=[]; //let extraInfo="";
  const b2bInfoExtraDetails=ticketSummary.b2bInfoExtraDetails;

  if(b2bInfoExtraDetails!==undefined && b2bInfoExtraDetails!==null && b2bInfoExtraDetails!=="" && b2bInfoExtraDetails!=="manually" && b2bInfoExtraDetails!=="mannually"
  && b2bInfoExtraDetails!=="{\"source\":\"manual\"}"){
    //extraInfo=b2b.raw_data;
    //console.log("ERROR SOURCE ", b2b.raw_data);
    const extraInfoEntry=JSON.parse(b2bInfoExtraDetails);
    //const dfeKey= Date.now();
    for(let okey in extraInfoEntry){
      //extraInfo +=okey+":"+extraInfoEntry[okey]+"<br/>\n";
      //const dfeKey= Date.now();
      const rndKey=Math.floor(Math.random()*100);
      //extraInfo.push(<><span className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}</span><br/></>);
      //extraInfo.push(<span className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}<br/></span>);
      extraInfo.push(<p className="lstp" key={"px"+rndKey}>{okey}: {extraInfoEntry[okey]}</p>);
    }

  }

  let neoemailTxt="";
  if(this.neoemail!==undefined && this.neoemail!==""){
    neoemailTxt="?neoemail="+this.neoemail;
  }

  const clientAddress=this.state.clientAddress;
  let followupPrecision="";
  const followupPrecisionx=ticketSummary.followupPrecision;
  if(followupPrecisionx!==undefined && followupPrecisionx!==null && followupPrecisionx!==""){
    followupPrecision=""+followupPrecisionx;
  }

//   "zqwNDICItxl_x": "Conflits d'Intérêts",
// "zqwNDICItx2_x": "Recherche de conflit d'interêts",
// "zqwNDICItx3_x": "Recherche",
// "zqwNDICItx4_x": "Ignorer",
// "zqwNDICItx5_x": "Non appliqué",
// "zqwNDICItx6_x": "Aucune information disponible",
// "zqwNDICItx7_x": "Aucun conflit",
// "zqwNDICItx8_x": "Conflit",
// "zqwNDICItx9_x": "Pas de registre de recherche",

  const neoSearch=ticketSummary.neoSearch;
  let neoSearchStatus="zqwNDICItx9_x";
  let totneoSearch=0;
  if(neoSearch!==undefined && neoSearch!==null && neoSearch.length >0){
     let totrec=0;
     const neoSearch0=neoSearch[0];
     totneoSearch=neoSearch.length;
     //if(neoSearch0.status==){}
      if(neoSearch0.status==="4"){
        neoSearchStatus="zqwNDICItx7_x";
      }else if(neoSearch0.status==="5"){
        neoSearchStatus="zqwNDICItx8_x";
      }else if(neoSearch0.status==="3"){
        neoSearchStatus="zqwNDICItx6_x";
      }else if(neoSearch0.status==="2"){
        neoSearchStatus="zqwNDICItx5_x";
      }else if(neoSearch0.status==="1"){
        neoSearchStatus="zqwNDICItxl0_x";
      }else if(neoSearch0.status==="0"){
        neoSearchStatus="zqwNDICItxl1_x";
      }else{
        neoSearchStatus="none";
      }
     //const extraInfoEntry=JSON.parse(b2bInfoExtraDetails);
    // for(let searchObj in neoSearch){
    // }
  }


  const NeoSearchResultHistory=this.NeoSearchResultHistory;
  //ticket?ifr=neosales&limTab=2
  //const urlParams = new URLSearchParams(window.location.search);
  //const iframeNeosales = urlParams.get('ifr');
  const iframeNeosales = this.getParameterByName('ifr');
  const limTab = this.getParameterByName('limTab');
  let fulltabs=true;
  if(iframeNeosales!==null && iframeNeosales==="neosales" && limTab!==null && (limTab==="2" || limTab==="3")){
    fulltabs=false;
  }

  const iframesolotab=this.getParameterByName('iframesolotab');

  console.log("weeeeeeeeeeell iframeNeosales-TAB ", iframeNeosales, limTab, fulltabs);
  console.log("weeeeeeeeeeell iframeNeosales-TAB #2 ", iframeNeosales, limTab, fulltabs, iframesolotab);

  //<NeoVentesFormB2b currentTab={"tab2"} />
  if(iframesolotab==="tabb2b"){
    return (
      <div className="iamneombz">
        <div className='tabsolodiv2'>
        <NeoVentesFormB2b currentTab={"tab2"} /></div>
      </div>);
  }else if(iframesolotab==="tabdocli"){
    return (
      <div className="iamneombz">
        <div className='tabsolodiv2'>
        <NdiFieldsFormzendesk hideNotes={iframeNeosales === "psj"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} /> 
        </div>
      </div>);
  }else if(iframesolotab==="tabaddprod"){
    return (
      <div className="iamneombz">
        <div  className="tabsolodiv2">
        <NdiFieldsFormzendesk currentTab={"tab3"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
        </div>
      </div>);
  }else if(iframesolotab==="tabdocu"){
    return (
      <div className="iamneombz">
        <div  className="tabsolodiv2">
        <p className = "font-16"><strong>DOCUMENTS: </strong> </p>
         <p className = "font-16"> &nbsp; </p>
         <NdiFieldsFormzendesk currentTab={"tab4"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
         </div>
      </div>);
  }else if(iframesolotab==="tabsum"){
    return (
      <div className="iamneombz">
         <div  className="tabsolodiv2">
         {fulltabs===true?
          <>
          <NeoSearchResultHistory   /></>:""
          }

            {(ticketSummary.client!==undefined && ticketSummary.client.firstname!==undefined)?
            <>
            <p style={{display: "flex", gap: "1rem"}}><b>{genLang({en: "Lawyer in charge", fr: "Avocat en charge"})}</b>{ticketSummary?.lawyerIncharge ?? "N/A"}</p>
            <p><b>{t("zqwNDICItx2_x")}</b></p>
            <p>- <span className={(totneoSearch>0)?"fk":"red"}>{t(neoSearchStatus)}</span>
            {(totneoSearch>0)?
            <span> &nbsp; (<a href="#" onClick={this.handleOpenNeoSearch}>{totneoSearch}</a>) </span>:''
            }
            </p>

            <p><b>{t("zqwTSumtxt1_x")}</b></p>
            <p>- {t("zqwTSumtxt2_x")} : {statutdo} </p>
            {/* <p>- {t("zqwTSumtxt3_x")} : {statutdon} <span className="rndtag">zaaaaaaaaaaa</span></p>  */}
            <p>- {t("zqwTSumtxt3_x")} : {statusdonarList}</p>
            {followupPrecision!==""?<p>&nbsp; &nbsp; &nbsp;{t("zqwTSumtxt3_2_x")} : {followupPrecision}</p>:''}
            <p><b>{t("zqwTSumtxt4_x")}</b>
              {/* Source du client */}
              </p>



            {(b2bCompanyName!==null && b2bCompanyName!=="")?
              <div className="neoinline100 leftxt"> {transactionProdList}
                  <Alert severity="info">
                    <b>{t("zqwTSumtxt5_x")} : {b2bCompanyName}</b><br/>
                    {b2bContractName}<br/>
                    {t("zqwTSumtxt6_x")}  : {nopolice}
                  </Alert>
              </div> :
              <div className="neoinline100 leftxt">{transactionProdList}
                  {/* <Alert severity="warning">
                    {b2bContractName}
                  </Alert> */}
              </div>
            }
            {(b2bCompanyName!==null && b2bCompanyName!=="" && b2bInfoExtraDetails!==null)?
            <div className="form-group">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{t('zqwTNVFtxt4_x')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="form-control-idem container containerx2">{extraInfo}</div>
                </AccordionDetails>
              </Accordion>
            </div>:''
            }

            {(personType==="2")?
              <><p><b>{t("zqwTSCnycompany_x")}</b></p>
              <p>{clientInfox}<br/>{clientInfo2x}<br/>{clientInfo5x}<br/>{clientInfo6x}<br/>{clientInfo3x}<br/>{clientInfo4x}<br/>{clientInfo7x}<br/>{clientInfo8x}<br/></p></>:''
            }
            {(personType==="2")?
              <p><b>{t("zqwTSCnyrepre_x")}</b></p>:<p><b>{t("zqwTSumtxt7_x")}</b></p>
            }
            <p>{clientInfo}<br/>{clientInfo2}<br/>{clientInfo5}<br/>{clientInfo3}<br/>{clientInfo4}<br/></p>
            <p><b>{t("zqwTSumtxt8_x")}</b></p>
            <p>- {language}</p>
            <p><b>{t("zqwTSumtxt9_x")}</b></p>
            <p>- {region}</p>
            <p><b>{t("zqwTSumtxt10_x")}</b></p>
            <p>- {natureDossier}</p>
            <p><b>{t("zqwTSumtxt10b_x")}</b></p>
            {rendezvous}
            <p> &nbsp; </p>
            <p> &nbsp; </p>
            </>:<p>{t("zqwTSumtxt11_x")}</p>
          }
         </div>
      </div>);
  }







  return (
    <div className="iamneombz">

{/*	 <h1 className="product-title font-32 text-theme-colored"> infoProduct_title </h1>
      <div className="line-bottom short-description ">

          <p className="font-16">Vous pouvez utiliser nos services pour :
          <br/>- Obtenir des conseils juridiques sur votre situation
          <br/>- Ouvrir votre dossier auprès de la Cour des petites créances
          <br/>- Vous former à présenter votre dossier devant le juge
          <br/><br/> <strong>Noter que l'envoi d'une lettre de mise en demeure avant d'entamer des procédures aux petites créances est exigé dans la grande majorité des cas. Pour envoyer une mise en demeure </strong></p>

      </div>
*/}

{/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
  Open Menu <ViewListIcon />
</Button> */}

{/* <IconButton
              aria-label="show more"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
            >
  <ViewListIcon />
</IconButton> */}

{/* <ChangeLanguage /> */}
<div className="form-group">
  {/* <div className="col-6 md-3"><ChangeLanguage /></div> */}
  <div style={{display:'inline-block'}}><ChangeLanguage /></div>
  {/* <div style={{display:'inline-block'}}><a href={"https://crm2.neolegal.ca/zendesk/"+ticket_id+"/ticket"} target="_blank" className="btn btn-primary">{t("zqwTSumtxt19_x")}</a></div> */}
 {this.clientOnZendeskSCR >0?
  <div style={{display:'inline-block'}}><a href={"https://crm2.neolegal.ca/zendesk/"+ticket_id+"/ticket"+neoemailTxt} target="_blank" className="btn btn-primary">{t("zqwTSumtxt19_x")}</a></div>:''
  }
</div>

{/* {fulltabs===true?
<>
<NdiInterestConflict ticket_id={""+ticket_id} client_id={""+client_id} />
<NeoSearchResultHistory   /></>:""
}  */}

{fulltabs===true && API_DOMAIN === 'http://127.0.0.1:8055/api'?
<NdiInterestConflict ticket_id={""+ticket_id} client_id={""+client_id} />:""
}

{fulltabs===true?
<>
<NeoSearchResultHistory   /></>:""
}

{/* <div className="form-group">
  <div className="col-6 md-3">
  <a href={"https://crm2.neolegal.ca/zendesk/"+ticket_id+"/ticket"} target="_blank" className="btn btn-primary">Ouvrir grande fenêtre</a>
  </div>
</div> */}


{/* <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>

  <MenuItem>
    <ChangeLanguage />
  </MenuItem>

</Menu> */}

      <AppBar position="static" color="default">
        {fulltabs===true?
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={t("zqwTabsummary_x")} {...this.a11yProps(0)} />
          <Tab label={t("zqwTabinput_x")}  {...this.a11yProps(1)} />
          <Tab label={t("zqwTabnouveau_x")}  {...this.a11yProps(2)} />
          <Tab label={t("zqwTabAjoutProd_x")}  {...this.a11yProps(3)} />
          {iframeNeosales !== "psj" && <Tab label={t("zqwTabDocument_x")}  {...this.a11yProps(4)} />}



          {/* <Tab label={whyNeolegal}  {...this.a11yProps(1)} />
          <Tab label="Other 3" {...this.a11yProps(2)} />
          <Tab label="Other 4" {...this.a11yProps(3)} />  */}
        </Tabs>:
        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label={t("zqwTabsummary_x")} {...this.a11yProps(0)} />
        <Tab label={t("zqwTabinput_x")}  {...this.a11yProps(1)} />
        {(iframeNeosales!==null && iframeNeosales==="neosales" && limTab!==null &&  limTab==="3")?
            <Tab label={t("zqwTabDocument_x")}  {...this.a11yProps(3)} /> :""
          }
        {/* <Tab label={t("zqwTabnouveau_x")}  {...this.a11yProps(2)} />
        <Tab label={t("zqwTabAjoutProd_x")}  {...this.a11yProps(3)} />
        <Tab label={t("zqwTabDocument_x")}  {...this.a11yProps(4)} />  */}

      </Tabs>
        }
      </AppBar>
      <TabPanel value={value} index={0}  className="iamneombz2 neospxSum">
      {/* <ConflictSearchAlert productId={this.state.productId} domain={this.state.ticketSummary?.zendeskduplex?.domainInLaw ?? ""} /> */}
        {/* <p className = "font-16"><strong>OTHER TAB #1: </strong> </p>
        <ul className="list text-black theme-colored angle-right  ">
          <li  key={1} className="pl-20  font-18">  descr.value  </li>
		      <li  key={2} className="pl-20  font-18">  descr.value2  </li>
        </ul>  */}

		    {/* <NdiFieldsFormzendesk  /> */}

        {/* <NeoVentesFormB2b  />  */}

        {(ticketSummary.client!==undefined && ticketSummary.client.firstname!==undefined)?
        <>
        <p style={{display: "flex", gap: "1rem"}}><b>{genLang({en: "Lawyer in charge", fr: "Avocat en charge"})}</b>{ticketSummary?.lawyerIncharge ?? "N/A"}</p>
        <p><b>{t("zqwNDICItx2_x")}</b></p>
        <p>- <span className={(totneoSearch>0)?"fk":"red"}>{t(neoSearchStatus)}</span>
        {(totneoSearch>0)?
        <span> &nbsp; (<a href="#" onClick={this.handleOpenNeoSearch}>{totneoSearch}</a>) </span>:''
        }
        </p>

        <p><b>{t("zqwTSumtxt1_x")}</b></p>
        <p>- {t("zqwTSumtxt2_x")} : {statutdo} </p>
        {/* <p>- {t("zqwTSumtxt3_x")} : {statutdon} <span className="rndtag">zaaaaaaaaaaa</span></p>  */}
        <p>- {t("zqwTSumtxt3_x")} : {statusdonarList}</p>
        {followupPrecision!==""?<p>&nbsp; &nbsp; &nbsp;{t("zqwTSumtxt3_2_x")} : {followupPrecision}</p>:''}
        <p><b>{t("zqwTSumtxt4_x")}</b>
          {/* Source du client */}
          </p>



        {(b2bCompanyName!==null && b2bCompanyName!=="")?
          <div className="neoinline100 leftxt"> {transactionProdList}
              <Alert severity="info">
                <b>{t("zqwTSumtxt5_x")} : {b2bCompanyName}</b><br/>
                 {b2bContractName}<br/>
                 {t("zqwTSumtxt6_x")}  : {nopolice}
              </Alert>
          </div> :
          <div className="neoinline100 leftxt">{transactionProdList}
              {/* <Alert severity="warning">
                {b2bContractName}
              </Alert> */}
          </div>
        }
        {(b2bCompanyName!==null && b2bCompanyName!=="" && b2bInfoExtraDetails!==null)?
        <div className="form-group">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t('zqwTNVFtxt4_x')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="form-control-idem container containerx2">{extraInfo}</div>
            </AccordionDetails>
          </Accordion>
        </div>:''
        }

        {(personType==="2")?
          <><p><b>{t("zqwTSCnycompany_x")}</b></p>
          <p>{clientInfox}<br/>{clientInfo2x}<br/>{clientInfo5x}<br/>{clientInfo6x}<br/>{clientInfo3x}<br/>{clientInfo4x}<br/>{clientInfo7x}<br/>{clientInfo8x}<br/></p></>:''
        }
        {(personType==="2")?
          <p><b>{t("zqwTSCnyrepre_x")}</b></p>:<p><b>{t("zqwTSumtxt7_x")}</b></p>
        }
        <p>{clientInfo}<br/>{clientInfo2}<br/>{clientInfo5}<br/>{clientInfo3}<br/>{clientInfo4}<br/></p>
        <p><b>{t("zqwTSumtxt8_x")}</b></p>
        <p>- {language}</p>
        <p><b>{t("zqwTSumtxt9_x")}</b></p>
        <p>- {region}</p>
        <p><b>{t("zqwTSumtxt10_x")}</b></p>
        <p>- {natureDossier}</p>
        <p><b>{t("zqwTSumtxt10b_x")}</b></p>
        {rendezvous}
        <p> &nbsp; </p>
        <p> &nbsp; </p>
        </>:<p>{t("zqwTSumtxt11_x")}</p>
      }











      </TabPanel>
       <TabPanel value={value} index={1}>
       <ConflictSearchAlert productId={this.state.productId} domain={this.state.ticketSummary?.zendeskduplex?.domainInLaw ?? ""} />
        {/* <NdiNeoChargeDeskzendesk  /> */}

          {/* <p className = "font-16"><strong>OTHER TAB #2: </strong> </p> */}

          <NdiFieldsFormzendesk hideNotes={iframeNeosales === "psj"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
           
      </TabPanel>
      <TabPanel value={value} index={2}>
         {/* <p className = "font-16"><strong>OTHER TAB #3: </strong> </p> */}

         {/* <NeoVentesFormB2b currentTab={"tab2"} />  */}
         {(iframeNeosales!==null && iframeNeosales==="neosales" && limTab!==null &&  limTab==="3")?
            <>
              <p className = "font-16"><strong>DOCUMENTS: </strong> </p>
              <p className = "font-16"> &nbsp; </p>
              <NdiFieldsFormzendesk currentTab={"tab4"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
            </>:<NeoVentesFormB2b currentTab={"tab2"} />
          }

      </TabPanel>
      <TabPanel value={value} index={3}>
         {/* <p className = "font-16"><strong>OTHER TAB #4: </strong> </p>
         Ajouter produits  au #232358<br/>
         Onglet pour ajouter les produits et historique des produits achetés
         pour ce dossier, en incluant les virements interac<br/>

         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO </strong> </p>
         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO </strong> </p>
         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO </strong> </p> */}

         <NdiFieldsFormzendesk currentTab={"tab3"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
         {/* {(iframeNeosales!==null && iframeNeosales==="neosales" && limTab!==null &&  limTab==="3")?
            <>
              <p className = "font-16"><strong>DOCUMENTS: </strong> </p>
              <p className = "font-16"> &nbsp; </p>
              <NdiFieldsFormzendesk currentTab={"tab4"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
            </>:<NdiFieldsFormzendesk currentTab={"tab3"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />
          }      */}

      </TabPanel>
      <TabPanel value={value} index={4}>
         <p className = "font-16"><strong>DOCUMENTS: </strong> </p>
         <p className = "font-16"> &nbsp; </p>
         {/* <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO 3</strong> </p>
         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO 3</strong> </p>
         <p className = "font-16"><strong>OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO 3</strong> </p> */}
         <NdiFieldsFormzendesk currentTab={"tab4"} clientAddress={clientAddress} currentTabTicket={this.props.currentTabTicket} />

      </TabPanel>
    </div>
  );


  }

  TabPanel = (props) => {
    //return (<div className="text-center mt-20 mt-sm-20">Bonjour</div>);
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }


  a11yProps= (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
}

function ConflictSearchAlert({ domain, productId }) {
  const [open, setOpen] = useState(true);
  const renderMessage = () => {
    const domainLabel = domains.find(i => (i.value === domain))?.label ?? "";
    if (productId === "172" || productId === "210") {
      return genLang({
        en: `Amicable divorce - Check the conflict of interest for the <b>customer</b> and the <b>additional party</b> (and not the opposing party) / because it is a joint mandate and the implicated if it's happened`,
        fr: `Divorce amiable - Vérifier le conflit d'intérêt pour le <b>client</b> et la <b>partie additionnelle</b> (et non partie adverse) / car c'est un mandat conjoint et les <b>mis-en-cause</b> s'il y a lieu`
      });
    } else if (domain === "446") {
      return genLang({
        en: `${domainLabel} File - Check the conflict of interest for the <b>client and the opposing party and the respondent if applicable</b>`,
        fr: `Dossier ${domainLabel} - Vérifier le conflit d'intérêt pour le <b>client et la partie adverse et le mis-en-cause s'il y a lieu</b>`
      });
    } else if (domain === "447" || domain === "448") {
      return genLang({
        en: `${domainLabel} File - Check conflict of interest only for <b>the customer</b>, there is no opposing party per se`,
        fr: `Dossier ${domainLabel} - Vérifier le conflit d'intérêt seulement pour <b>le client</b> , il n'y a pas de partie adverse en soi`
      });
    } else if (domain === "449") {
      return genLang({
        en: `${domainLabel} File - In the event of incorporation, check the conflict of interest for each of the shareholders as <b>customer</b> And <b>additional part</b>`,
        fr: `Dossier ${domainLabel} - En cas d'incorporation, vérifier le conflit d'intérêt pour chacun des actionnaires en tant que <b>client</b> et <b>partie additionnelle</b>`
      });
    } 
    return genLang({
      en: `${domainLabel} File - Always check the conflict of interest for the <b>client</b> and the <b>opposing party</b>`,
      fr: `Dossier ${domainLabel} - Vérifiez toujours le conflit d'intérêt pour le <b>client</b> et la <b>partie adverse</b>`
    });
  }

  const domains = [
    { label: "Civil", value: "445" },
    { label: "Civil - Vices cachés", value: "442" },
    { label: "Famille", value: "446" },
    { label: "Pénal", value: "447" },
    { label: "Notariat", value: "448" },
    { label: "Corporatif", value: "449" },
    { label: "Criminel", value: "450" }
  ];

  const message = renderMessage();

  return (
    <>
      {open && (
        <Alert
          severity="info"
          style={{ marginBottom: "0.5rem" }}
          onClose={() => setOpen(false)}
          children={<div dangerouslySetInnerHTML={{ __html: message }} />}
        />
      )}
    </>
  );
}

//const TransNdiFieldsForm = withTranslation()(NdiFieldsMiniBoardzendesk);
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

const mapDispatchToProps = { addOrUpdateNdiFields, getNdiFields, getTicketSummary, addOrUpdateTicketData };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NdiFieldsMiniBoardzendesk));
