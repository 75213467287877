import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addOrUpdateNeoVentes,
  addNeoVentesSC,
} from "../../actions/NeoVentesAction";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Toastr from "../../messages";
import Loader from "../../loader";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import StripePayment from "../../StripePayment";
import getProvinceTaxInfo from "../../appTaxes";
import { encryptText, decryptText } from "../../appEncryption";
import { listOfRefundAgent, listOfIntearcAgent } from "../../appConfig";

class NeoMiniPayment extends Component {
  //validation=new Validations();
  actionMode = "new";
  requestTimer = null;
  backInvoiceId = 0;
  stripeAvoidManyTokens = 0;
  stripeTryTimes = 0;
  stripeNeoTokenTrack = 0;
  paymentBtnTryTimes2 = 0;
  paymentBtnTryTimes3 = 0;

  constructor(props) {
    super(props);
    this.stateInit = {
      id: 0,
      name: "",
      e_name: false,
      description: "",
      e_description: false,
      amount: "",
      e_amount: false,

      productCart: [],
      productId: "",
      productTitre: "",
      product: {},
      productPrice: "",
      productPriceTax: "",
      productPriceTaxOnly: 0,
      productExtraOptions: [],
      productExtraOptionCtrl: null,
      productExtraOptionCtrl2: [],
      showProductOption: false,
      prodTagPrice: [],
      prodTagPriceTax: [],
      prodTagPriceTaxOnly: [],
      prodTagTotOptions: [],
      grandTotal: 0,

      idClient: "0",
      firstname: "",
      e_firstname: false,
      lastname: "",
      e_lastname: false,
      cell: "",
      e_cell: false,
      terms: "",
      e_terms: false,
      active: "",
      e_active: false,
      created: "",
      e_created: false,
      email: "",
      e_email: false,
      id_users: "0",
      e_id_users: false,
      language: "fr",
      e_language: false,
      push: "",
      e_push: false,
      id_zendesk: "",
      e_id_zendesk: false,
      sms: 1,
      e_sms: false,
      stripe_id: "",
      e_stripe_id: false,

      idAddress: 0,
      address: "",
      e_address: false,
      unitsuite: "",
      e_unitsuite: false,
      city: "",
      e_city: false,
      province: "QC",
      e_province: false,
      province2: "",
      cp: "",
      e_cp: false,
      country: "Canada",
      e_country: false,
      company: "",
      e_company: false,
      existedClientRO: false,
      openPossibleZipCode: false,
      listAddressOnCode: null,
      listZipCodeAddresses: [],
      countryTuner: "",
      listPromotionCodes: [],
      codepromoOptions: [],
      openInfoStepsPopup: false,
      infoStepsPopupno: 0,
      infoProduct: { data: [] },
      infoProduct_title: "",
      infoProduct_excerpt: "",
      partialCanClose: 0,
      openTransactionPopup: false,
      twoStepSale: "",

      listOfSuggestion: [],
      valueClientSearch: "",
      inputValueClientSearch: "",
      selectedIdSuggestion: "",

      listOfSuggestion2: [],
      valueClientSearch2: "",
      inputValueClientSearch2: "",
      selectedIdSuggestion2: "",

      clientInfo: {},
      clientAddress: {},
      globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close
      workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod

      stripePaymentOpen: false,
      isLoaderOpen: false,
      toastrMessage: "",
      toastrType: "",
      toastrDuration: 6000,
      cuponcode: "",
      cuponcodeId: 0,
      cuponcodeName: "",
      showPromoLine: false,
      promoAmount: 0,
      promoPercentage: 0,
      promoTax1: 0,
      promoTax2: 0,
      orderBruteSubTotal: 0,
      orderNetSubTotal: 0,
      orderTVQ: 0,
      orderTPS: 0,
      orderTotal: 0,

      stripeToken: "",
      dossier: "", //421
      isRecurrentPayment: 0,
      nowPayment: 0,
      qtyPayment: 1,
      laterpayment: 4,
      fromDatePayment: "",
      paymentRound: 0,
      paymentWay: "full",
      nowPaymentRO: true,
      recurPaymentAmount: 0,
      paymentsReceived: [0],
      totalPaymentReceived: 0,
      paymentWayRO: false,
      beforePaymentTotal: 0,
      id_invoice: 0,
      pMethod: 1,
      status_invoice: "",
      showProdMulti: 0,
      productsList: { products: [] },
      openPListAsOnTheSite: false,
      errorTransacMessage: "",
      emailDoesNotExist: 0,
      openPossibleListOfClient: false,
      listClientOnCode: [],
      possibleListOfClient: [],
      statusChargeDesk: 0,
      chargeDeskIdStr: "",
      openPossibleCDStatusInfo: false,
      possibleCDStatusInfo: {},
      possibleListOfCDStatusInfo: [],
      taxInfo: {},
      isupsale: false,
      upsaleTicket: "",
      isAmerindian: false,
      no_amerindien: "",
      listOfTickets: [],
      goProd: 0,
      newTicket: false,
      currentTicket: "",
      openWinSTransaction: false,
      whichDate: "2",
      interacCDeskDate: "",
      productListFilter: "QC",
    };

    this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);

    this.deleteXRecordsMain = this.deleteXRecordsMain.bind(this);
    this.updateXRecordsMain = this.updateXRecordsMain.bind(this);
  }

  productsList = { products: [] }; // this.state.productsList; // productList();

  handleChange = (event) => {
    const { t } = this.props;
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    this.setState({ ["productCart"]: [], productId: "" });
    this.setState({ ["grandTotal"]: 0, ["beforePaymentTotal"]: 0 });
    this.setState({
      ["orderBruteSubTotal"]: 0,
      ["orderNetSubTotal"]: 0,
      ["orderTVQ"]: 0,
      ["orderTPS"]: 0,
      ["orderTotal"]: 0,
    });
    this.setState({ ["nowPayment"]: 0 });

    const clientProdTransInfo = this.props.clientProdTransInfo;
    const client = clientProdTransInfo.client;
    let stripeId = "";
    if (client !== undefined && client !== null) {
      this.setState(client);
      this.setState({
        ["clientInfo"]: client,
        ["idClient"]: parseInt(client.id),
      });
    }

    if (this.props.productsList.products.length > 0) {
      this.productsList = this.props.productsList;
    }
    if (this.props.productsList.products.length > 0) {
      this.productsList = this.props.productsList;
    }
    if (
      this.props.currentTicket !== undefined &&
      this.props.currentTicket !== null &&
      this.props.currentTicket !== ""
    ) {
      this.setState({ ["currentTicket"]: this.props.currentTicket });
    }

    if (
      this.props.clientAddress !== undefined &&
      this.props.clientAddress !== null
    ) {
      const taxInfo = getProvinceTaxInfo(this.props.clientAddress.province); //QC
      this.setState({
        ["clientAddress"]: this.props.clientAddress,
        ["province"]: this.props.clientAddress.province,
        ["taxInfo"]: taxInfo,
      });
    } else if (
      this.props.provinceInfotax !== undefined &&
      this.props.provinceInfotax !== null
    ) {
      const taxInfo = getProvinceTaxInfo(this.props.provinceInfotax); //QC
      this.setState({
        ["province"]: this.props.provinceInfotax,
        ["taxInfo"]: taxInfo,
      });
    }

    console.log(
      "WEEEEEEEEEEEE MINIPAYMENT provinceInfotax ",
      this.props.clientAddress.province,
      this.props.provinceInfotax
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openx !== this.props.openx) {
      if (this.props.openx) {
        this.setState({ ["productCart"]: [], productId: "" });
        this.setState({ ["grandTotal"]: 0, ["beforePaymentTotal"]: 0 });
        this.setState({
          ["orderBruteSubTotal"]: 0,
          ["orderNetSubTotal"]: 0,
          ["orderTVQ"]: 0,
          ["orderTPS"]: 0,
          ["orderTotal"]: 0,
        });
        this.setState({ ["nowPayment"]: 0, ["paymentRound"]: 0 });
      }
    }
    if (
      prevProps.provinceInfotax !== this.props.provinceInfotax &&
      this.props.provinceInfotax !== undefined &&
      this.props.provinceInfotax !== null
    ) {
      const taxInfo = getProvinceTaxInfo(this.props.provinceInfotax); //QC
      this.setState({
        ["province"]: this.props.provinceInfotax,
        ["taxInfo"]: taxInfo,
      });
    }
  }

  handleCheckBoxCheck = (event) => {
    let isChecked = false;
    if (event.target.checked) {
      isChecked = true;
    }
    this.setState({ [event.target.name]: isChecked });
  };

  getStripStatus = (stripobject) => {
    const result = stripobject;
    const stripeNow = Date.now();
    const timesbetween = stripeNow * 1 - this.stripeTryTimes * 1;
    const stripeNeoToken = result.stripeNeoToken;
    if (timesbetween < 60000) {
      return false;
    } else {
      this.stripeTryTimes = stripeNow;
      this.stripeNeoTokenTrack = stripeNeoToken;
    }

    this.setState({
      ["stripePaymentOpen"]: false,
      ["stripeToken"]: result.token.id,
      ["isLoaderOpen"]: true,
    });
    this.showToast("Premiere etape, veuillez patieter un instant", "success");
    this.stripeAvoidManyTokens = 1;
    setTimeout(() => {
      this.handleMakePaymentSC(null);
    }, 100);
  };

  payWithtInterac = (event) => {
    const { t } = this.props;
    event.preventDefault();
    this.setState({ ["pMethod"]: 3 });
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const currentTicket = this.state.currentTicket;
    const productIdone = this.state.productId;
    if (productIdone === null || productIdone === "") {
      this.showToast(t("zqwNVFrmEl7_x"), "error");
      return false;
    }

    if (currentTicket === "") {
      this.showToast(t("zqwMniPEl_x"), "error");
      return false;
    }

    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }

    setTimeout(() => {
      this.handleMakePaymentSC(event);
    }, 100);
  };

  payWithtChargeDesk = (event) => {
    const { t } = this.props;
    event.preventDefault();
    //this.settate({ ["isLoaderOpen"]: true}); return false;
    this.setState({ ["pMethod"]: 4 });
    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const currentTicket = this.state.currentTicket;
    const productIdone = this.state.productId;
    if (productIdone === null || productIdone === "") {
      this.showToast(t("zqwNVFrmEl7_x"), "error");
      return false;
    }

    if (currentTicket === "") {
      this.showToast(t("zqwMniPEl_x"), "error");
      return false;
    }

    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }

    setTimeout(() => {
      this.handleMakePaymentSC(event);
    }, 100);
  };

  payWithCardOnFile = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const currentTicket = this.state.currentTicket;
    const productIdone = this.state.productId;
    if (productIdone === null || productIdone === "") {
      console.log(
        "//EEEEEEEEEEROR 333333333333, NADA MAL  00000 z=",
        this.state.idClient,
        this.state.stripe_id
      );
      this.showToast(t("zqwNVFrmEl7_x"), "error");
      return false;
    }

    if (currentTicket === "") {
      console.log(
        "//EEEEEEEEEEROR2 333333333333, NADA MAL  00000 z=",
        this.state.idClient,
        this.state.stripe_id
      );
      this.showToast(t("zqwMniPEl_x"), "error");
      return false;
    }

    const clientProdTransInfo = this.props.clientProdTransInfo;
    const client = clientProdTransInfo.client;

    const paytryNow = Date.now();
    const timesbetween = paytryNow * 1 - this.paymentBtnTryTimes2 * 1;
    if (timesbetween < 60000) {
      //this.stripeTryTimes=paytryNow;
      const timerest = 60 - timesbetween / 1000;
      this.showToast(t("zqwNVFrmEl16_x", { timerest: timerest }), "error");
      return false;
    } else {
      this.paymentBtnTryTimes2 = paytryNow;
    }
    this.setState({ ["pMethod"]: 1, ["stripeToken"]: "CARD_ON_FILE" });
    setTimeout(() => {
      this.handleMakePaymentSC(event);
    }, 100);
  };

  handleMakePaymentSC = (event) => {
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {
      idClient: idClient,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
      occupation: occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if (unitsuite !== "") {
      address = unitsuite + "-" + address;
    }
    const neoAddress = {
      idAddress: idAddress,
      id_clients: id_clients,
      address: address,
      city: city,
      province: province,
      cp: cp,
      country: country,
      active: active2,
      tel: tel,
      ext: ext,
      company: company,
      name: nameNx2,
    };

    const productId = this.state.productCart.length; //const productId=this.state.productId;
    const productPriceTotal = this.state.grandTotal;

    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    const neoVentes = [];

    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    const downPayment = this.state.nowPayment;

    const subNeoVentes = {
      isSub: true,
      id: 0,
      tax1: 0,
      tax2: 0,
      sub_total: 0,
      tax_total: 0,
      total: downPayment,
      amount: downPayment,
      options: [],
      tarifBase: downPayment,
      createTicket: true,
      pages: 0,
      perPage: 0,
      productPrice: 0,
      productPrice2: downPayment,
    };
    neoVentes.push(subNeoVentes);

    const tarifAmountx = downPayment; //subTotal+totTagTotOptions;
    const tax1x = 0;
    const tax2x = 0;
    const tax_totalx = 0;
    const totalForItemx = (tarifAmountx * 1 + tax_totalx * 1).toFixed(2); //const totalForItem= ((tarifAmountx+tax_total)).toFixed(2);
    const mainNeoVentesx = {
      isSub: false,
      tax1: tax1x,
      tax2: tax2x,
      sub_total: tarifAmountx,
      tax_total: tax_totalx,
      total: totalForItemx,
    };
    neoVentes.push(mainNeoVentesx);

    const stripeToken = this.state.stripeToken;
    let pMethod = 1;

    const dossier = this.state.dossier;
    pMethod = this.state.pMethod;

    const paymentRound = this.state.paymentRound;
    const isRecurrent = this.state.isRecurrentPayment;
    const qtyPayment = this.state.qtyPayment;
    const laterpayment = this.state.laterpayment;
    const fromDatePayment = this.state.fromDatePayment;
    const paymentWay = this.state.paymentWay; //full, partial, recurrent
    const neoPaymentsx = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const id_invoice = this.state.id_invoice;

    let currentTicket = this.state.currentTicket;
    const isNewTicket = this.state.newTicket;
    const productIdone = this.state.productId;
    if (productIdone === null || productIdone === "") {
      this.showToast(t("zqwNVFrmEl7_x"), "error");
      return false;
    }

    if (currentTicket === "") {
      this.showToast(t("zqwMniPEl_x"), "error");
      return false;
    }

    const clientProdTransInfo = this.props.clientProdTransInfo;
    const client = clientProdTransInfo.client;
    if (
      client === undefined ||
      client.id === undefined ||
      client.id === null ||
      client.id == "0"
    ) {
      //neoClients = {idClient
      this.showToast(t("zqwMniPEl_x") + " client ID ", "error");
      return false;
    }

    const neoVentes2 = [];

    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;
    productCart.length > 0 &&
      productCart.map((item, i) => {
        const isDelete = item.isdelete;
        const myItemtxt = "" + item.id;
        if (myItemtxt.indexOf("98989") < 0 && isDelete === undefined) {
          totProd++;
          const productPrice =
            item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
          const tarifBase = parseFloat(productPrice) * 1; //(parseFloat(item.productPrice)*1);
          const tarifAmount = tarifBase + parseFloat(item.totOptions);
          subTotal += parseFloat(productPrice); //parseFloat(item.productPrice);
          totTagTotOptions += parseFloat(item.totOptions);
          const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
          const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
          const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
          const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2);
          const options = item.options;
          const cticket = item.cticket;
          const pages = item.pages;
          const perPage = item.perPage;
          const subNeoVentes = {
            isSub: true,
            id: item.id,
            tax1: tax1,
            tax2: tax2,
            sub_total: tarifAmount,
            tax_total: tax_total,
            total: totalForItem,
            amount: tarifAmount,
            options: options,
            tarifBase: tarifBase,
            createTicket: cticket,
            pages: pages,
            perPage: perPage,
            productPrice: item.productPrice,
            productPrice2: item.productPrice2,
          };
          neoVentes2.push(subNeoVentes);
        }
      }, this);

    if (totProd === 0) {
      this.showToast(t("zqwNVFrmEl9_x"), "error");
      return false;
    }

    const tarifAmount = subTotal + totTagTotOptions;
    const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
    const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
    const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
    const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {
      isSub: false,
      tax1: tax1,
      tax2: tax2,
      sub_total: tarifAmount,
      tax_total: tax_total,
      total: totalForItem,
    };
    neoVentes2.push(mainNeoVentes);

    let neoPromocode = {
      id: 0,
      percentage: 0,
      name: name,
      amount: 0,
      tax1: 0,
      tax2: 0,
    };
    const cuponcodeId = this.state.cuponcodeId;
    const cuponcodeName = this.state.cuponcodeName;
    const promoAmount = this.state.promoAmount;
    const promoPercentage = this.state.promoPercentage;
    const promoTax1 = this.state.promoTax1;
    const promoTax2 = this.state.promoTax2;
    if (cuponcodeId > 0) {
      neoPromocode = {
        id: cuponcodeId,
        percentage: promoPercentage,
        name: cuponcodeName,
        amount: promoAmount,
        tax1: promoTax1,
        tax2: promoTax2,
      };
    }

    const neoPayments = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };
    const isupsale = this.state.isupsale;
    const upsaleTicket = this.state.upsaleTicket;
    const isAmerindian = this.state.isAmerindian;
    const no_amerindien = this.state.no_amerindien;
    if (isupsale === true && (upsaleTicket === null || upsaleTicket === "")) {
      this.showToast(t("zqwNVFrmEl11_x"), "error");
      return false;
    }
    if (
      isAmerindian === true &&
      (no_amerindien === null || no_amerindien === "")
    ) {
      this.showToast(t("zqwNVFrmEl12_x"), "error");
      return false;
    }
    const neoVentesBlocksOldschool = {
      client: neoClients,
      address: neoAddress,
      sales: neoVentes,
      promocode: neoPromocode,
      payment: neoPayments,
      dossier: dossier,
      pMethod: pMethod,
      stripeToken: stripeToken,
      id_invoice: id_invoice,
      isupsale: isupsale,
      upsaleTicket: upsaleTicket,
      isAmerindian: isAmerindian,
      no_amerindien: no_amerindien,
    };
    const tkx = parseInt(currentTicket);
    let tkx2 = 0;
    let tkx3 = 0;
    if (
      this.props.curTicket2 !== undefined &&
      this.props.curTicket2 !== null &&
      this.props.curTicket2 !== ""
    ) {
      tkx2 = parseInt(this.props.curTicket2);
    }
    if (
      this.props.curTicket3 !== undefined &&
      this.props.curTicket3 !== null &&
      this.props.curTicket3 !== ""
    ) {
      tkx3 = parseInt(this.props.curTicket3);
    }

    if (tkx !== NaN && tkx > 0) {
    } else if (tkx2 !== NaN && tkx2 > 0) {
      currentTicket = tkx2;
    } else if (tkx3 !== NaN && tkx3 > 0) {
      currentTicket = tkx3;
    }

    let errorFound = 0;
    const neoVentesBlocks00 = {
      client: neoClients,
      address: neoAddress,
      sales: neoVentes,
      promocode: neoPromocode,
      payment: neoPayments,
      dossier: dossier,
      pMethod: pMethod,
      stripeToken: stripeToken,
      id_invoice: id_invoice,
    };

    const neoVentesBlocksV1 = {
      clientId: client.id,
      productIdone: productIdone,
      isNewTicket: isNewTicket,
      currentTicket: currentTicket,
      dossier: dossier,
      pMethod: pMethod,
      stripeToken: stripeToken,
      id_invoice: id_invoice,
    };

    const neoVentesBlocks = {
      clientId: client.id,
      productIdone: productIdone,
      isNewTicket: isNewTicket,
      currentTicket: currentTicket,
      client: neoClients,
      address: neoAddress,
      sales: neoVentes2,
      promocode: neoPromocode,
      payment: neoPayments,
      dossier: dossier,
      pMethod: pMethod,
      stripeToken: stripeToken,
      id_invoice: id_invoice,
      isupsale: isupsale,
      upsaleTicket: upsaleTicket,
      isAmerindian: isAmerindian,
      no_amerindien: no_amerindien,
    };

    if (
      currentTicket === NaN ||
      currentTicket === "0" ||
      currentTicket === 0 ||
      currentTicket === ""
    ) {
      this.showToast(t("zqwMniPEl_x") + " ... ", "error");
      return false;
    }
    console.log("currentTicket=====currentTicket", currentTicket);

    let rawError = {};
    const this00 = this;

    this.setState({ ["isLoaderOpen"]: true });
    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived = this.state.totalPaymentReceived;
    let paymentsReceived = this.state.paymentsReceived;

    Promise.resolve(this.props.addNeoVentesSC(neoVentesBlocks, 1))
      .then((response) => {
        //const resp=response.NeoVentes.transaction;
        const resp = response.NeoVentes;
        rawError = response;
        console.log("RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ");
        console.log(resp); //paymentRound errorTransacMessage
        //transaction.
        if (
          resp.closeId === 1 &&
          resp.transaction.paymentAmount > 0 &&
          resp.transaction.id_invoice > 0
        ) {
          this.setState({ ["paymentRound"]: 1, ["errorTransacMessage"]: "" });
          if (pMethod !== 1) {
            this.setState({
              ["errorTransacMessage"]:
                "Attention c'est un paiement en attente qui sera finalisé par le système ou quand le client fait le viremennt de fonds par service client.",
            });
          }

          this.showToast(t("zqwNVFrmEl13_x"), "success");
        } else {
          this.setState({
            ["paymentRound"]: 0,
            ["errorTransacMessage"]: "Échec de paiement/Payment failed",
          });
        }
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        this00.setState({ ["isLoaderOpen"]: false });
        const errObj = error;
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
      });
  };

  handleClosePaymentSC = (event) => {
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {
      idClient: idClient,
      firstname: firstname,
      lastname: lastname,
      cell: cell,
      terms: terms,
      active: active,
      email: email,
      id_users: id_users,
      language: language,
      push: push,
      id_zendesk: id_zendesk,
      sms: sms,
      stripe_id: stripe_id,
      occupation: occupation,
    };

    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if (unitsuite !== "") {
      address = unitsuite + "-" + address;
    }
    const neoAddress = {
      idAddress: idAddress,
      id_clients: id_clients,
      address: address,
      city: city,
      province: province,
      cp: cp,
      country: country,
      active: active2,
      tel: tel,
      ext: ext,
      company: company,
      name: nameNx2,
    };

    const productId = this.state.productCart.length; //const productId=this.state.productId;
    const productPriceTotal = this.state.grandTotal;

    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const neoVentes = [];

    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    const downPayment = this.state.nowPayment;

    const subNeoVentes = {
      isSub: true,
      id: 0,
      tax1: 0,
      tax2: 0,
      sub_total: 0,
      tax_total: 0,
      total: downPayment,
      amount: downPayment,
      options: [],
      tarifBase: downPayment,
      createTicket: true,
      pages: 0,
      perPage: 0,
      productPrice: 0,
      productPrice2: downPayment,
    };
    neoVentes.push(subNeoVentes);

    const tarifAmountx = downPayment; //subTotal+totTagTotOptions;
    const tax1x = 0;
    const tax2x = 0;
    const tax_totalx = 0;
    const totalForItemx = (tarifAmountx * 1 + tax_totalx * 1).toFixed(2); //const totalForItem= ((tarifAmountx+tax_total)).toFixed(2);
    const mainNeoVentesx = {
      isSub: false,
      tax1: tax1x,
      tax2: tax2x,
      sub_total: tarifAmountx,
      tax_total: tax_totalx,
      total: totalForItemx,
    };
    neoVentes.push(mainNeoVentesx);

    const stripeToken = this.state.stripeToken;
    let pMethod = 1;

    const dossier = this.state.dossier;
    pMethod = this.state.pMethod;

    //const downPayment=this.state.nowPayment;
    const paymentRound = this.state.paymentRound;
    const isRecurrent = this.state.isRecurrentPayment;
    const qtyPayment = this.state.qtyPayment;
    const laterpayment = this.state.laterpayment;
    const fromDatePayment = this.state.fromDatePayment;
    const paymentWay = this.state.paymentWay; //full, partial, recurrent
    const neoPaymentsx = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const id_invoice = this.state.id_invoice;
    const idInvoice = this.props.salesCloseInvId;

    //********************************************************************************
    let currentTicket = this.state.currentTicket;
    const isNewTicket = this.state.newTicket;
    //const productIdone=parseInt(this.state.productId);
    const productIdone = this.state.productId;

    if (currentTicket === "") {
      this.showToast(t("zqwMniPEl_x"), "error");
      return false;
    }

    if (idInvoice === undefined || idInvoice === null || idInvoice === "0") {
      this.showToast(t("zqwMniPEl_x") + " #Facture/Invoice ID ", "error");
      return false;
    }

    const clientProdTransInfo = this.props.clientProdTransInfo;
    const client = clientProdTransInfo.client;
    //const transactions=clientProdTransInfo.transactions;
    if (
      client === undefined ||
      client.id === undefined ||
      client.id === null ||
      client.id == "0"
    ) {
      //neoClients = {idClient
      this.showToast(t("zqwMniPEl_x") + " client ID ", "error");
      return false;
    }
    console.log(
      "woooooooooooooo id, productIdone, currentTicket, isNewTicket ",
      client.id,
      productIdone,
      currentTicket,
      isNewTicket
    );
    console.log("woooooooooooooo cardonfile ", client);
    console.log(
      "//OOOOOOOOOOOOOOOOO 333333337777, NADA MAL  00000 z=",
      this.state.idClient,
      this.state.stripe_id
    );
    //return false;

    const neoVentes2 = [];

    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;

    const tarifAmount = subTotal + totTagTotOptions;
    const tax1 = (tarifAmount * 1 * TAX1).toFixed(2);
    const tax2 = (tarifAmount * 1 * TAX2).toFixed(2);
    const tax_total = (tarifAmount * TAX_TOTAL).toFixed(2);
    const totalForItem = (tarifAmount * 1 + tax_total * 1).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {
      isSub: false,
      tax1: tax1,
      tax2: tax2,
      sub_total: tarifAmount,
      tax_total: tax_total,
      total: totalForItem,
    };
    neoVentes2.push(mainNeoVentes);

    let neoPromocode = {
      id: 0,
      percentage: 0,
      name: name,
      amount: 0,
      tax1: 0,
      tax2: 0,
    };

    const neoPayments = {
      id: id,
      downPayment: downPayment,
      paymentRound: paymentRound,
      paymentWay: paymentWay,
      isRecurrent: isRecurrent,
      qtyPayment: qtyPayment,
      recPaymentDay: laterpayment,
      recPaymentDate: fromDatePayment,
    };

    const isupsale = this.state.isupsale;
    const upsaleTicket = this.state.upsaleTicket;
    const isAmerindian = this.state.isAmerindian;
    const no_amerindien = this.state.no_amerindien;
    if (isupsale === true && (upsaleTicket === null || upsaleTicket === "")) {
      this.showToast(t("zqwNVFrmEl11_x"), "error");
      return false;
    }
    if (
      isAmerindian === true &&
      (no_amerindien === null || no_amerindien === "")
    ) {
      this.showToast(t("zqwNVFrmEl12_x"), "error");
      return false;
    }

    ////////////////////////////////////////////////////////////////////////////
    //currentTicket===""
    const tkx = parseInt(currentTicket);
    let tkx2 = 0;
    let tkx3 = 0;
    if (
      this.props.curTicket2 !== undefined &&
      this.props.curTicket2 !== null &&
      this.props.curTicket2 !== ""
    ) {
      tkx2 = parseInt(this.props.curTicket2);
    }
    if (
      this.props.curTicket3 !== undefined &&
      this.props.curTicket3 !== null &&
      this.props.curTicket3 !== ""
    ) {
      tkx3 = parseInt(this.props.curTicket3);
    }

    if (tkx !== NaN && tkx > 0) {
    } else if (tkx2 !== NaN && tkx2 > 0) {
      currentTicket = tkx2;
    } else if (tkx3 !== NaN && tkx3 > 0) {
      currentTicket = tkx3;
    }

    const whichDate = this.state.whichDate;
    const interacCDeskDate = this.state.interacCDeskDate;

    let errorFound = 0;

    const neoVentesBlocks = {
      clientId: client.id,
      makeCloseOfSale: 7,
      currentTicket: currentTicket,
      id_invoice: idInvoice,
      id_invoice00: id_invoice,
    };

    neoVentesBlocks.whichDate = whichDate;
    neoVentesBlocks.interacCDeskDate = interacCDeskDate;

    if (
      currentTicket === NaN ||
      currentTicket === "0" ||
      currentTicket === 0 ||
      currentTicket === ""
    ) {
      this.showToast(t("zqwMniPEl_x") + " ... ", "error");
      return false;
    }
    let rawError = {};
    const this00 = this;

    this.setState({ ["isLoaderOpen"]: true });
    Promise.resolve(this.props.addNeoVentesSC(neoVentesBlocks, 1))
      .then((response) => {
        const resp = response.NeoVentes;
        rawError = response;
        if (resp.closeId === 1 && resp.transaction.id > 0) {
          this.setState({ ["paymentRound"]: 1, ["errorTransacMessage"]: "" });
          this.showToast(t("zqwNVFrmEl13_x"), "success");
        } else {
          this.setState({
            ["paymentRound"]: 0,
            ["errorTransacMessage"]: "Échec de fermeture de vente failed",
          });
        }
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        this00.setState({ ["isLoaderOpen"]: false });
        const errObj = error;
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage += "\n " + t("zqwNVFrmEl14_x");
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
      });
  };

  handleProductChange = (event) => {
    const { t } = this.props;
    const selVal = event.value; //productId
    this.setState({ ["productId"]: selVal });
    this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]: 2 });
    this.workflowProductList = true;
    const product = this.getProductChange(selVal);
    this.applyProductChange(selVal);
  };

  handleProductChange2 = (event) => {
    const { t } = this.props;
    const selVal = event.value; //productId
    this.setState({ ["productId"]: selVal });
    const product = this.getProductChange(selVal);
    this.applyProductChange(selVal);
    this.showToast(t("zqwNVFrmEl28_x") + " #" + product.titre, "success");
  };

  getProductChange = (inputValue) => {
    let productItem = {};
    const products = this.props.productsList.products;

    products.length > 0 &&
      products.map((item, i) => {
        if (
          item.type.indexOf("hidden") < 0 &&
          item.titre !== "" &&
          item.id == inputValue
        ) {
          productItem = item;
          return item;
        }
      });

    console.log("TOT-PROD", products.length);

    return productItem; //return false;
  };

  setMultiactions = (eventargs) => {
    const closeme = eventargs.closeme;
    if (closeme !== null && closeme !== undefined && closeme === "close") {
      const obj = {};
    }
  };

  closeFolderProductPopup = (anyEvent) => {
    this.setState({ ["openProductFolder"]: false });
    const obj = { closeme: "close" };
    if (this.state.paymentRound > 0) {
      obj.refreshafterPayment = 1;
    }
    this.props.multiactions(obj);
  };

  handleTransactionChange = (event) => {
    //event.preventDefault();
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal = event.value; //productId
    this.setState({ ["transactionId"]: selVal });
  };

  showToast = (arg, toastType) => {
    this.setState({
      toastrMessage: arg,
      toastrType: toastType,
    });
    setTimeout(() => {
      this.setState({
        toastrMessage: "",
        toastrType: "success",
      });
    }, 5500);
  };

  deleteXRecordsMain(selectedRows) {
    const objList = this.props.NeoProducts;
    let gridIndexes = [];
    let gridIds = [];
    selectedRows.data.map((row) => {
      gridIndexes.push(row.index);
      return null;
    });

    gridIndexes.reverse();
    for (const index of gridIndexes) {
      const obj = objList[index];
      gridIds.push(obj.id);
      objList.splice(index, 1);
    }
    this.props.deleteNeoProducts(gridIds[0]);
  }

  updateXRecordsMain(selectedRows) {
    const { t } = this.props;
    const objList = this.props.NeoProducts;
    let gridIndexes = [];
    let idIndex = -1;
    selectedRows.data.map((row) => {
      gridIndexes.push(row.index);
      idIndex = row.index;
      return null;
    });

    if (gridIndexes.length > 1) {
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }

    let obj = objList[idIndex];
    this.props.getNeoProductsGoto(obj.id);
    //history.push(`./neoproducts/${obj.id}/edit`);
  }

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  renderProductToggle = () => {
    const provinceOptions = [
      { label: "Quebec", value: "QC" },
      { label: "Ontario", value: "ON" },
    ];
    return (
      <div style={{ width: "35%" }}>
        <label>Province</label>
        <Select
          options={provinceOptions}
          onChange={(e) => this.setState({ productListFilter: e.value })}
          value={provinceOptions.find(
            (i) => i.value === this.state.productListFilter
          )}
        />
      </div>
    );
  };

  render() {
    const open = this.props.openx;
    const { t } = this.props;
    let productOptions = [];

    let productList =
      this.props.productsList.products.length > 0 &&
      this.props.productsList.products.filter(i => i?.province === this.state.productListFilter).map((item, i) => {
        if (item.type.indexOf("hidden") < 0 && item.titre !== "") {
          productOptions.push({
            value: item.id,
            label: item.titre + " (" + item.tarif + ")",
          });
        }
        return null;
      }, this);

    let transactionOptions = [];

    let id_products = "";
    let transactions_id = "";
    let transactionOption = {};
    let productOption = {};
    const clientProdTransInfo = this.props.clientProdTransInfo;
    const client = clientProdTransInfo.client;
    const transactions = clientProdTransInfo.transactions;
    if (transactions !== null && transactions !== undefined) {
      if (transactions !== null && transactions.id_products !== undefined) {
        id_products = transactions.id_products;
        productOption = {
          value: transactions.id_products,
          label:
            transactions.description +
            " (prod#" +
            transactions.id_products +
            " inv#" +
            transactions.id_invoices +
            ")",
        };
        transactionOptions.push({
          value: transactions.id_products,
          label:
            transactions.description +
            " (prod#" +
            transactions.id_products +
            " inv#" +
            transactions.id_invoices +
            ")",
        });
      } else if (
        transactions[0].id !== null &&
        transactions[0].id !== undefined
      ) {
        id_products = transactions[0].id_products;
        productOption = {
          value: transactions[0].id_products,
          label:
            transactions[0].description +
            " (prod#" +
            transactions[0].id_products +
            " inv#" +
            transactions[0].id_invoices +
            ")",
        };
        transactionOptions.push({
          value: transactions[0].id_products,
          label:
            transactions[0].description +
            " (prod#" +
            transactions[0].id_products +
            " inv#" +
            transactions[0].id_invoices +
            ")",
        });
      }
      if (transactions !== null && transactions.id !== undefined) {
        transactions_id = transactions.id;
        transactionOptions.push({
          value: transactions.id,
          label:
            transactions.description +
            " (prod#" +
            transactions.id_products +
            " inv#" +
            transactions.id_invoices +
            ")",
        });
        transactionOption = {
          value: transactions.id,
          label:
            transactions.description +
            " (prod#" +
            transactions.id_products +
            " inv#" +
            transactions.id_invoices +
            ")",
        };
      } else if (
        transactions[0].id !== null &&
        transactions[0].id !== undefined
      ) {
        transactions_id = transactions[0].id;
        transactionOptions.push({
          value: transactions[0].id,
          label:
            transactions[0].description +
            " (prod#" +
            transactions[0].id_products +
            " inv#" +
            transactions[0].id_invoices +
            ")",
        });
        transactionOption = {
          value: transactions[0].id,
          label:
            transactions[0].description +
            " (prod#" +
            transactions[0].id_products +
            " inv#" +
            transactions[0].id_invoices +
            ")",
        };
      }
    }

    let stripeId = "";
    if (client !== undefined && client !== null) {
      if (client.stripe_id !== undefined && client.stripe_id !== null) {
        stripeId = client.stripe_id;
      }
    }

    const globalStepWorkflow = this.state.globalStepWorkflow;
    const myToastMessage = this.state.toastrMessage;
    const toastrType = this.state.toastrType;
    const Alert = this.Alert;

    const agents = listOfIntearcAgent; //listOfRefundAgent;
    let allowRefundBtn = false;
    let userId = 0;
    const profile = decryptText(localStorage.profile);
    if (profile !== undefined && profile !== null && profile !== "") {
      const profileObj = JSON.parse(profile);
      if (
        profileObj !== undefined &&
        profileObj !== null &&
        profileObj.id_users !== null
      ) {
        userId = profileObj.id_users;
      }
    }
    if (agents.includes(parseInt(userId))) {
      allowRefundBtn = true;
    }

    const taxInfo = this.state.taxInfo;
    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }

    const promoAmount = "-" + this.state.promoAmount;
    const orderBruteSubTotal = this.state.orderBruteSubTotal;
    const orderNetSubTotal = this.state.orderNetSubTotal;
    const orderTVQ = this.state.orderTVQ;
    const orderTPS = this.state.orderTPS;
    const orderTotal = this.state.orderTotal;
    const promoPercentage = this.state.promoPercentage;

    const totalPaymentReceived = this.state.totalPaymentReceived;
    const beforePaymentTotal = this.state.beforePaymentTotal;

    const productPriceTotal = this.state.grandTotal; //orderTotal
    const nowPayment = this.state.nowPayment;
    const paymentWay = this.state.paymentWay;
    const paymentValid = {
      total: productPriceTotal,
      paymentWay: paymentWay,
      nowPayment: nowPayment,
    };
    const paymentRound = this.state.paymentRound;
    const ProductsOnInvoice = this.ProductsOnInvoice;
    const salesCloseId = this.props.salesCloseId;
    const salesCloseInvId = this.props.salesCloseInvId;

    if (salesCloseId === "3") {
      return (
        <div>
          <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={this.handleCloseTransactionPopup}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="form-dialog-productFolder"
          >
            <DialogContent id="form-dialog-productFolder">
              <div className="text-center">
                <h5>{t("zqwNVFrmEl78d1_x")}</h5>
              </div>

              <div className="form-group minP">&nbsp;</div>
              {agents.includes(parseInt(userId)) === true ? (
                <div className="form-group">
                  <label>Choisir une option de fermeture</label>
                  <br />
                  <input
                    type="radio"
                    className={"form-controlx"}
                    id="whichDate1"
                    name="whichDate"
                    value="1"
                    checked={this.state.whichDate === "1" ? true : false}
                    onClick={this.handleChange}
                  />
                  <span for="whichDate1"> Date d’aujourd’hui</span>
                  <br />
                  <input
                    type="radio"
                    className={"form-controlx"}
                    id="whichDate2"
                    name="whichDate"
                    value="2"
                    checked={this.state.whichDate === "2" ? true : false}
                    onClick={this.handleChange}
                  />
                  <span for="whichDate2"> Date de l’achat</span>
                  <br />
                  <input
                    type="radio"
                    className={"form-controlx"}
                    id="whichDate3"
                    name="whichDate"
                    value="3"
                    checked={this.state.whichDate === "3" ? true : false}
                    onClick={this.handleChange}
                  />
                  <span for="whichDate3"> Choisir Date</span>
                </div>
              ) : (
                ""
              )}
              {this.state.whichDate === "3" ? (
                <div className="form-group">
                  <label htmlFor="interacCDeskDate">Date</label>
                  <input
                    type="date"
                    name="interacCDeskDate"
                    id="interacCDeskDate"
                    value={this.state.interacCDeskDate}
                    onChange={this.handleChange}
                    className={"form-control"}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="form-group minP">
                {paymentRound === 0 ? (
                  <button
                    name="finaliseSale"
                    type="submit"
                    onClick={this.handleClosePaymentSC}
                    className="btn btn-lg btn-rounded btn-cons btn-complete text-white"
                  >
                    {t("zqwNFFrmZn020_x")}
                  </button>
                ) : (
                  ""
                )}
              </div>

              {paymentRound > 0 ? (
                <div className="form-group">
                  <Alert severity="success">
                    Félicitations, on a finalisé la vente avec succès!!
                    Facture/Invoice #{salesCloseInvId}
                  </Alert>
                  <br /> &nbsp; <br />
                </div>
              ) : (
                ""
              )}

              {this.state.errorTransacMessage !== "" ? (
                <div className="form-group">
                  <Alert severity="error">
                    {this.state.errorTransacMessage}
                  </Alert>
                  <br /> &nbsp; <br />
                </div>
              ) : (
                ""
              )}

              <div className="form-row neoxtbl">
                <div className="form-group col-md-9">
                  <br />
                  <input
                    type="button"
                    onClick={this.closeFolderProductPopup}
                    className="btn btn-success"
                    value={t("zqwTNVFtxt20_x")}
                  />{" "}
                  &nbsp; &nbsp;
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Toastr
            messagex={myToastMessage}
            open={true}
            toastType={toastrType}
          />
          <Loader openx={this.state.isLoaderOpen} />
        </div>
      );
    }

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseTransactionPopup}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-productFolder"
        >
          <DialogContent id="form-dialog-productFolder">
            <div className="text-center">
              <h5>{t("zqwPuptxt1_x")}</h5>
              {/* AJOUTER DES PRODUITS AU TICKET */}
            </div>

            <div className="form-group">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {this.renderProductToggle()}
                <div style={{ width: "100%" }}>
                  <label htmlFor="name">{t("zqwPuptxt2_x")}</label>
                  <Select
                    options={productOptions}
                    onChange={this.handleProductChange}
                  />
                </div>
              </div>
            </div>
            <div className="form-group minP">
              <input
                type="checkbox"
                name="newTicket"
                id="newTicket"
                className="form-controlx"
                checked={this.state.newTicket}
                onClick={this.handleCheckBoxCheck}
                placeholder={"nouveau/new ticket"}
                onChange={this.handleCheckBoxCheck}
              />{" "}
              {t("zqwNFFtxtNeo_x")}
            </div>

            <div className="row minP">
              <ProductsOnInvoice />
            </div>

            <div className="row minP">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <div className="row">
                  <div className="neoinline50">{t("zqwNVFrmEl57_x")}</div>
                  <div className="neoinline50">
                    {" "}
                    <span id="subtotal">{orderBruteSubTotal}</span>$
                  </div>
                </div>

                {TAX1_NAME !== "0" ? (
                  <div
                    className="row"
                    id="tax1_block"
                    style={{ display: "block" }}
                  >
                    <div className="neoinline50 text-right">
                      <span id="tax1_name">
                        {TAX1_NAME !== "0" ? TAX1_NAME : ""}
                      </span>{" "}
                      (<span id="tax1_amount">{TAX1lx}</span>%)
                    </div>
                    <div className="neoinline50 text-right text-black">
                      {" "}
                      <span id="tax1">{orderTVQ}</span>$
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {TAX2_NAME !== "0" ? (
                  <div
                    className="row"
                    id="tax2_block"
                    style={{ display: "block" }}
                  >
                    <div className="neoinline50">
                      <span id="tax2_name">
                        {TAX2_NAME !== "0" ? TAX2_NAME : ""}
                      </span>{" "}
                      (<span id="tax2_amount">{TAX2lx}</span>%)
                    </div>
                    <div className="neoinline50">
                      {" "}
                      <span id="tax2">{orderTPS}</span>$
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row minP">
                  <div className="neoinline100">
                    <hr />
                  </div>
                </div>

                <div className="row bold minP">
                  <div className="neoinline50">{t("zqwNVFrmEl61_x")}</div>
                  <div className="neoinline50">
                    {" "}
                    <span id="total">{orderTotal}</span>$
                  </div>
                </div>
              </div>
            </div>

            {paymentRound === 0 ? (
              <div className="text-center">
                {/* <p> </p> 
				<p> </p>   */}
                <p> </p>
                <StripePayment
                  openx={this.state.stripePaymentOpen}
                  valid={paymentValid}
                  stripestatus={this.getStripStatus}
                />

                {stripeId !== "" ? (
                  <button
                    type="button"
                    name="pcardOnFile"
                    className="btn btn-info"
                    onClick={this.payWithCardOnFile}
                  >
                    {t("zqwNVFrmEl75_x")}
                  </button>
                ) : (
                  ""
                )}
                {stripeId !== "" ? (
                  <>
                    &nbsp; &nbsp;
                    <br />{" "}
                  </>
                ) : (
                  ""
                )}
                <button
                  name="interac"
                  type="submit"
                  value="Payer avec "
                  onClick={this.payWithtInterac}
                  className="btn btn-lg btn-rounded btn-cons btn-complete text-white"
                >
                  {t("zqwNVFrmEl73_x")}{" "}
                  <img
                    width="32"
                    src="/static/assets/img/cards/4.png"
                    alt="Interac"
                  />
                </button>

                <button
                  name="chargedesk"
                  type="submit"
                  value="Payer avec "
                  onClick={this.payWithtChargeDesk}
                  className="btn btn-lg btn-rounded btn-cons btn-complete text-white"
                >
                  {t("zqwNVFrmEl73_x")} Charge Desk
                </button>
              </div>
            ) : (
              ""
            )}

            {paymentRound > 0 ? (
              <div className="form-group">
                <Alert severity="success">
                  Félicitations, paiement reçu avec succès!! Paiement #
                  {paymentRound}
                </Alert>
                <br /> &nbsp; <br />
              </div>
            ) : (
              ""
            )}

            {this.state.errorTransacMessage !== "" ? (
              <div className="form-group">
                <Alert severity="error">{this.state.errorTransacMessage}</Alert>
                <br /> &nbsp; <br />
              </div>
            ) : (
              ""
            )}

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.closeFolderProductPopup}
                  className="btn btn-success"
                  value={t("zqwTNVFtxt20_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        <Loader openx={this.state.isLoaderOpen} />
      </div>
    );
  }

  applyProductChange = (inputValue) => {
    const { t } = this.props;
    const taxInfo = this.state.taxInfo;
    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }

    console.log("MINIPayment aaaaaaaaaply on TOOOOOOOOOOOOOOOP ");

    const TAX_TOTAL = TAX1 + TAX2; //0.14975;

    const arrProd = [6, 8, 9];

    const productsList = this.props.productsList;

    productsList.products.length > 0 &&
      productsList.products.map((item, i) => {
        if (
          item.type.indexOf("hidden") < 0 &&
          item.titre !== "" &&
          item.id == inputValue
        ) {
          let tarif = ("" + item.tarif).replace(/\W\$/g, "");
          tarif = tarif.replace("$", "");
          const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
          const productPriceTotal = (
            tarif * 1 * TAX1 +
            tarif * 1 * TAX2 +
            tarif * 1
          ).toFixed(2);
          const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
          const orderTPS = (tarif * 1 * TAX2).toFixed(2);
          const orderServices = 0;
          const orderSubtotals = (tarif * 1 + orderServices).toFixed(2);

          this.setState({ ["productTitre"]: item.titre });
          this.setState({ ["product"]: item });
          this.setState({ ["productPrice"]: tarif });
          this.setState({ ["productPriceTax"]: productPriceTotal });
          this.setState({ ["productPriceTaxOnly"]: productPriceTaxOnly });
          const optionsEntries =
            item.options != undefined && !arrProd.includes(item.id)
              ? Object.entries(item.options)
              : [];
          const cartEntries = Object.entries(item.cart);

          const this00 = this;

          let productCart = this.state.productCart;
          const indexProd = productCart.length;

          const apartir = item.apartir;
          let apartirUsed = false;
          const optionsProd = item.options;

          const productExtraOptionCtrl = optionsEntries.map(function (
            elem,
            index
          ) {
            const [txtLabel, amount] = elem;
            const groupVal = "" + indexProd + "-" + item.id + "-" + amount;
            let titre = "";
            for (const [txtLabel2, labelValue] of cartEntries) {
              if (txtLabel === txtLabel2) {
                titre = labelValue;
                break;
              }
            }
            if (titre === "") {
              return <span key={index} />;
            } else {
              return (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={amount}
                    onChange={(eventx) =>
                      this00.setExtraServiceForProduct(eventx)
                    }
                  />{" "}
                  {titre} (${amount})
                </p>
              );
            }
          });

          const productExtraOptionCtrl2 = optionsEntries.map(function (
            elem,
            index
          ) {
            const [txtLabel, amount] = elem;
            const groupVal =
              "" + indexProd + "-" + item.id + "-" + txtLabel + "-" + amount;
            let titre = "";
            for (const [txtLabel2, labelValue] of cartEntries) {
              if (txtLabel === txtLabel2) {
                titre = labelValue;
                break;
              }
            }
            if (titre === "" && apartir === false) {
              return <span key={index} />;
            }
            if (apartir === true) {
              //apartirUsed=true;
              if (apartirUsed === false) {
                apartirUsed = true;
                return (
                  <p key={index}>
                    {t("zqwNVFrmEl81_x")}:<br />
                    <input
                      type="number"
                      min="1"
                      step="1"
                      defaultValue={1}
                      data-value={groupVal}
                      data-perpage={optionsProd.perPage}
                      data-minimum={optionsProd.minimum}
                      data-maximum={optionsProd.maximum}
                      onChange={(eventx) => this.setPageDocForProduct(eventx)}
                    />{" "}
                    {t("zqwNVFrmEl82_x")} (n x ${amount})
                  </p>
                );
              } else {
                return <span key={index} />;
              }
            } else {
              //console.log(`##INDEEEEEEEEEEEEEEEEEX SET IS ${groupVal}  `);
              return (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={groupVal}
                    onChange={(eventx) =>
                      this00.setExtraServiceForProduct(eventx)
                    }
                  />{" "}
                  {titre} (${amount})
                </p>
              );
            }
          });

          const rId = Math.floor(Math.random() * 16);
          this.setState({ ["productExtraOptionCtrl"]: productExtraOptionCtrl });
          this.setState({ ["showProductOption"]: true });

          let productExtraOptionCtrl2x = this.state.productExtraOptionCtrl2;
          this.setState({
            ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x,
          });
          const myCart = {
            index: indexProd,
            id: item.id,
            productTitre: item.titre,
            productPrice: tarif,
            productPrice2: 0,
            productPriceTax: productPriceTotal,
            productPriceTaxOnly: productPriceTaxOnly,
            totOptions: 0,
            options: [],
            cticket: true,
            pages: 0,
            perPage: 0,
            randcolor: rId,
          };
          if (indexProd == 0) {
            productCart.push(myCart);
          } else {
            productCart[0] = myCart;
          }
          this.setState({ ["productCart"]: productCart });
          this.calculateGrandTotal(null);
          return false;
        }
        return null;
      }, this);
    return inputValue;
  };

  setNewPriceForProduct = (event) => {
    //event.preventDefault();
    const selVal = event.target.value; //productId
    const selVal2 = event.target.dataset.value;
    const arVal = selVal2.split("-");
    const index = arVal[0];
    const prodId = arVal[1]; //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);

    let productCart = this.state.productCart;

    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    productCart[index].productPrice2 = selVal;
    let totTagTotOptions = productCart[index].totOptions * 1; // 0;
    let optionsProd = []; //productCart[index].options;
    const productPrice =
      productCart[index].productPrice2 === 0
        ? productCart[index].productPrice
        : productCart[index].productPrice2;

    let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
    const orderTPS = (tarif * 1 * TAX2).toFixed(2);
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);
    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null);
  };

  handleRemoveProductItem = (event) => {
    const { t } = this.props;
    const index = event.target.dataset.prodindex; //const selVal= event.value;
    let productCart = this.state.productCart;
    let productExtraOptionCtrl2x = this.state.productExtraOptionCtrl2;
    const productTitre = productCart[index].productTitre;
    productExtraOptionCtrl2x[index] = <span key={index} />; //  productExtraOptionCtrl2x.splice(index, 1); ////productExtraOptionCtrl2x.push(divBlock);
    this.setState({ ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x });

    const myCart = {
      index: index,
      id: index + "98989",
      productTitre: "",
      productPrice: 0,
      productPrice2: 0,
      productPriceTax: 0,
      productPriceTaxOnly: 0,
      totOptions: 0,
      options: [],
      isdelete: 1,
    };
    productCart[index] = myCart; //productCart.splice(index, 1); //productCart.push(myCart);
    this.setState({ ["productCart"]: productCart });
    //this.calculateGrandTotal(null) ;
    this.showToast(t("zqwNVFrmEl123_x") + "  " + productTitre, "warning");
    this.calculateGrandTotal(null);
  };

  indexOfElement = (optionList, id, amount) => {
    let index = -1;
    optionList.length > 0 &&
      optionList.map((item, i) => {
        if (item.id === id && item.mnt === amount) {
          index = i;
          return i;
        }
      }, this);

    return index;
  };

  calculateGrandTotal = (event) => {
    const productCart = this.state.productCart;
    let subTotal = 0;
    let totTagTotOptions = 0;
    const taxInfo = this.state.taxInfo;

    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;

    productCart.length > 0 &&
      productCart.map((item, i) => {
        if (item.productTitre !== "") {
          const productPrice =
            item.productPrice2 === 0 ? item.productPrice : item.productPrice2;
          subTotal += parseFloat(productPrice); //item.productPrice
          totTagTotOptions += parseFloat(item.totOptions);
        }
      }, this);

    let tarif = subTotal * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL

    const percentage = this.state.promoPercentage;
    let tarifPromo = ((percentage * 1) / 100) * tarif;
    tarif = tarif - tarifPromo;

    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTVQ = (tarif * 1 * TAX1).toFixed(2);
    const productPriceTPS = (tarif * 1 * TAX2).toFixed(2);
    const productPriceTotalx = tarif * 1 * TAX_TOTAL + tarif * 1; //.toFixed(2) ;

    const totalPaymentReceived = this.state.totalPaymentReceived;

    let productPriceTotal = (productPriceTotalx - totalPaymentReceived).toFixed(
      2
    );
    if (productPriceTotal == "-0.00") {
      productPriceTotal = "0.00";
    }

    this.setState({
      ["grandTotal"]: productPriceTotal,
      ["beforePaymentTotal"]: productPriceTotalx.toFixed(2),
    });

    this.setState({
      ["orderBruteSubTotal"]: tarif,
      ["orderNetSubTotal"]: tarif,
      ["orderTVQ"]: productPriceTVQ,
      ["orderTPS"]: productPriceTPS,
      ["orderTotal"]: productPriceTotal,
    });
    this.setState({ ["nowPayment"]: productPriceTotal });
  };

  setExtraServiceForProduct = (event) => {
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    const selVal = event.target.value; //productId
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1];
    const prodOptId = arVal[2];
    const amountOpt = parseFloat(arVal[3]);

    let productCart = this.state.productCart;
    let totTagTotOptions = productCart[index].totOptions * 1;
    let optionsProd = productCart[index].options;

    if (event.target.checked) {
      totTagTotOptions += amountOpt;
      optionsProd.push({ id: prodOptId, mnt: amountOpt });
    } else {
      totTagTotOptions -= amountOpt;
      const indz = this.indexOfElement(optionsProd, prodOptId, amountOpt);
      if (indz > -1) {
        optionsProd.splice(indz, 1);
      }
    }

    productCart[index].totOptions = totTagTotOptions;
    let tarif = productCart[index].productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    productCart[index].options = optionsProd;
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null);
  };

  setTicketForProduct = (event) => {
    //(event) {
    const selVal = event.target.value; //productId
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1]; //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);

    let productCart = this.state.productCart;

    if (event.target.checked) {
      productCart[index].cticket = true;
    } else {
      productCart[index].cticket = false;
    }
    this.setState({ ["productCart"]: productCart });
  };

  setPageDocForProduct000 = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const selVal = event.target.dataset.value; //event.target.value;
    let selPage = event.target.value;
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1];
    const prodOptId = arVal[2];
    const amountOpt = parseFloat(arVal[3]);
    const maximum = event.target.dataset.maximum * 1;
    //const perPage=(event.target.dataset.perpage);
    const perPage = amountOpt;
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    if (selPage > maximum) {
      event.target.value = 1; //maximum;
      this.showToast(
        t("zqwNVFrmEl29_x", { maximum: maximum, selPage: selPage }),
        "error"
      );
    }

    let productCart = this.state.productCart;

    let totTagTotOptions = 0; //(productCart[index].totOptions*1);
    let optionsProd = []; //productCart[index].options;

    totTagTotOptions = (selPage - 1) * perPage;
    optionsProd.push({ id: "perPage", mnt: totTagTotOptions });

    productCart[index].totOptions = totTagTotOptions;
    const productPrice =
      productCart[index].productPrice2 === 0
        ? productCart[index].productPrice
        : productCart[index].productPrice2;
    console.log(
      "totOptions totOptions ",
      totTagTotOptions,
      selPage,
      perPage,
      "selVal",
      selVal
    );

    let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    productCart[index].options = optionsProd;
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);
    const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
    const orderTPS = (tarif * 1 * TAX2).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });

    productCart[index].options = optionsProd;
    productCart[index].pages = selPage > 0 ? selPage : 1;
    productCart[index].perPage = perPage;

    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null);
  };

  setPageDocForProduct = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const selVal = event.target.dataset.value; //event.target.value;
    let selPage = parseInt(event.target.value);
    const arVal = selVal.split("-");
    const index = arVal[0];
    const prodId = arVal[1];
    const prodOptId = arVal[2];
    const amountOpt = parseFloat(arVal[3]);
    const maximum = event.target.dataset.maximum * 1;
    const minimum = event.target.dataset.minimum * 1;
    //const perPage=(event.target.dataset.perpage);
    const perPage = amountOpt;
    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];

    if (selPage > maximum) {
      event.target.value = 1; //maximum;
      this.showToast(
        t("zqwNVFrmEl29_x", { maximum: maximum, selPage: selPage }),
        "error"
      );
      //return false;
    }

    let productCart = this.state.productCart;

    let totTagTotOptions = 0; //(productCart[index].totOptions*1);
    let optionsProd = []; //productCart[index].options;
    optionsProd = productCart[index].options;
    if (selPage <= minimum) {
      totTagTotOptions = 0;
    } else {
      totTagTotOptions = (selPage - minimum) * perPage;
    }

    optionsProd.id = "perPage";
    optionsProd.mnt = totTagTotOptions;
    //, pg:selPage, perPage:perPage

    productCart[index].totOptions = totTagTotOptions;
    const productPrice =
      productCart[index].productPrice2 === 0
        ? productCart[index].productPrice
        : productCart[index].productPrice2;

    let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    productCart[index].options = optionsProd;
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);
    const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
    const orderTPS = (tarif * 1 * TAX2).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    productCart[index].options = optionsProd;
    productCart[index].pages = selPage > 0 ? selPage : 1;
    productCart[index].perPage = perPage;

    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null);
  };

  setNewPriceForProduct = (event) => {
    const selVal = event.target.value; //productId
    const selVal2 = event.target.dataset.value;
    const arVal = selVal2.split("-");
    const index = arVal[0];
    const prodId = arVal[1]; //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);

    let productCart = this.state.productCart;

    const taxInfo = this.state.taxInfo;
    const TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    productCart[index].productPrice2 = selVal;

    let totTagTotOptions = productCart[index].totOptions * 1; // 0;
    let optionsProd = []; //productCart[index].options;
    const productPrice =
      productCart[index].productPrice2 === 0
        ? productCart[index].productPrice
        : productCart[index].productPrice2;

    let tarif = productPrice * 1 + totTagTotOptions; // (''+item.tarif).replace(/\W\$/g, '');
    const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
    const productPriceTotal = (tarif * 1 * TAX_TOTAL + tarif * 1).toFixed(2);
    const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
    const orderTPS = (tarif * 1 * TAX2).toFixed(2);
    const orderServices = totTagTotOptions;
    const orderSubtotals = (tarif * 1).toFixed(2);

    productCart[index].productPriceTax = productPriceTotal;
    productCart[index].productPriceTaxOnly = productPriceTaxOnly;
    this.setState({ ["productCart"]: productCart });
    this.calculateGrandTotal(null);
  };

  ProductsOnInvoice = (event) => {
    const { t } = this.props;
    const taxInfo = this.state.taxInfo;
    let TAX1 = parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2 = parseFloat(taxInfo["tax2x"]); //0.05; //tax2
    //const TAX_TOTAL=TAX1+TAX2; //0.14975;
    const TAX1_NAME = taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME = taxInfo["tax2_name"]; //"TPS";
    const TAX1l = taxInfo["tax1"]; //tax1 0.9975
    const TAX2l = taxInfo["tax2"];
    let TAX1lx = taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx = taxInfo["tax2"];
    let TAX12 = 0;
    let TAX21 = 0;
    if (TAX2_NAME === "0") {
      TAX1lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX1 = TAX1 + TAX2;
      TAX12 = TAX1 + TAX2;
      TAX2 = 0;
    }
    if (TAX1_NAME === "0") {
      TAX2lx = parseFloat(TAX1l) + parseFloat(TAX2l);
      TAX2 = TAX2 + TAX1;
      TAX21 = TAX1 + TAX2;
      TAX1 = 0;
    }
    const TAX_TOTAL = TAX1 + TAX2; //0.14975;
    const arrProd = [6, 8, 9];
    let productOptions = [];
    let productList =
      this.productsList.products.length > 0 &&
      this.productsList.products.map((item, i) => {
        if (item.type.indexOf("hidden") < 0 && item.titre !== "") {
          productOptions.push({
            value: item.id,
            label: item.titre + " (" + item.tarif + ")",
          });
        }
        return null;
      }, this);

    const ProductExtraOptionCtrl2 = this.state.productExtraOptionCtrl2;
    const grandTotal = this.state.grandTotal;
    const orderBruteSubTotal = this.state.orderBruteSubTotal;
    const orderTVQ = this.state.orderTVQ; //shows at the end
    const orderTPS = this.state.orderTPS; //shows at the end
    ///////////////////////////////////////////////////////////////////////////////////////
    let productCart = this.state.productCart;

    let subTotal = 0;
    let totTagTotOptions = 0;
    let totProd = 0;
    const listOfCurrentProducts =
      productCart.length > 0 &&
      productCart.map((itemState, i) => {
        const isDelete = itemState.isdelete;
        const myItemtxt = "" + itemState.id;
        const index = i;
        console.log("OUR-BASKET-i-" + i, itemState);
        if (myItemtxt.indexOf("98989") < 0 && isDelete === undefined) {
          const item = this.getProductChange(itemState.id);
          console.log("OUR-BASKET-PRODINFO", item);
          const optionsEntries =
            item.options != undefined && !arrProd.includes(item.id)
              ? Object.entries(item.options)
              : [];
          const cartEntries = Object.entries(item.cart);

          const this00 = this;

          let optionsProd = productCart[index].options;
          let selPage = productCart[index].pages;
          let perPage = productCart[index].perPage;
          if (selPage <= 1) {
            selPage = 1;
          }
          totTagTotOptions = productCart[index].totOptions;
          const productPrice =
            itemState.productPrice2 === 0
              ? itemState.productPrice
              : itemState.productPrice2;
          let tarif = productPrice * 1 + totTagTotOptions;
          let tarifPrix = productPrice;
          const productPriceTaxOnly = (tarif * 1 * TAX_TOTAL).toFixed(2);
          const productPriceTotal = (
            tarif * 1 * TAX1 +
            tarif * 1 * TAX2 +
            tarif * 1
          ).toFixed(2);
          const orderTVQ = (tarif * 1 * TAX1).toFixed(2);
          const orderTPS = (tarif * 1 * TAX2).toFixed(2);
          const orderServices = totTagTotOptions;
          const orderSubtotals = (tarif * 1).toFixed(2);
          const indexProd = index; //productCart.length;
          const apartir = item.apartir;
          let apartirUsed = false;
          const productExtraOptionCtrl2 = optionsEntries.map(function (
            elem,
            index
          ) {
            const [txtLabel, amount] = elem;
            const groupVal =
              "" + indexProd + "-" + item.id + "-" + txtLabel + "-" + amount;
            let titre = "";
            for (const [txtLabel2, labelValue] of cartEntries) {
              if (txtLabel === txtLabel2) {
                titre = labelValue;
                break;
              }
            }
            if (titre === "" && apartir === false) {
              return <span key={index} />;
            }
            if (apartir === true) {
              if (apartirUsed === false) {
                apartirUsed = true;
                return (
                  <p key={index}>
                    {t("zqwNVFrmEl81_x")}:<br />
                    <input
                      type="number"
                      min="1"
                      step="1"
                      defaultValue={selPage}
                      data-value={groupVal}
                      data-perpage={optionsProd.perPage}
                      data-minimum={optionsProd.minimum}
                      data-maximum={optionsProd.maximum}
                      onChange={(eventx) => this00.setPageDocForProduct(eventx)}
                    />{" "}
                    {t("zqwNVFrmEl82_x")} (n x ${amount})
                  </p>
                );
              } else {
                return <span key={index} />;
              }
            } else {
              let isSelected = false;
              const txtLabelx = txtLabel;
              const txtLabelzx = optionsProd.id;
              const indz = this00.indexOfElement(optionsProd, txtLabel, amount);
              if (indz > -1) {
                isSelected = true;
              }

              return (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={groupVal}
                    defaultChecked={isSelected}
                    onChange={(eventx) =>
                      this00.setExtraServiceForProduct(eventx)
                    }
                  />{" "}
                  {titre} (${amount})
                </p>
              );
            }
          });
          const rId = itemState.randcolor;
          const divBlock = (
            <div key={myItemtxt} className="marginr md-4">
              <div className="card card-default neocardx2">
                <div className={`card-header neocard${rId}`}>
                  <h5 className="m-0">{item.titre}</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="row" style={{ display: "block" }}>
                        <div className="neoinline50">
                          <span>{t("zqwNVFrmEl83_x")}</span>
                        </div>
                        <div className="neoinline50l" key={"iz" + indexProd}>
                          <input
                            type="number"
                            step="0.1"
                            min="1"
                            name="neoPrice"
                            defaultValue={tarifPrix}
                            data-value={indexProd + "-" + item.id}
                            onChange={(eventx) =>
                              this00.setNewPriceForProduct(eventx)
                            }
                            className="form-control col-md-8x"
                            size="30"
                            style={{ display: "inline", width: "100px" }}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="row" style={{ display: "block" }}>
                        <div className="neoinline50 text-right">
                          <span>{t("zqwNVFrmEl84_x")}</span>
                        </div>
                        <div className="neoinline50l">
                          {" "}
                          <span>{orderServices}</span>$
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  {optionsEntries.length > 0 && cartEntries.length > 0 ? (
                    <>
                      {t("zqwNVFrmEl86_x")}: <hr />
                    </>
                  ) : (
                    ""
                  )}

                  {productExtraOptionCtrl2}
                  <br />
                  <div className="ovalItemPrice">
                    <span>{productPriceTotal}</span>$
                  </div>
                  <p style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      value={indexProd + "-" + item.id}
                      defaultChecked
                      onChange={(eventx) => this00.setTicketForProduct(eventx)}
                    />{" "}
                    {t("zqwNVFrmEl87_x")}
                  </p>

                  {/* <a href="#" className="btn btn-danger" data-prodindex={indexProd} onClick={this.handleRemoveProductItem} >{t("zqwNVFrmEl88_x")}</a> */}
                </div>
              </div>
            </div>
          );

          return divBlock;
        }

        return null;
      }, this);

    return <div className="row">{listOfCurrentProducts}</div>;
  };
}

const mapStateToProps = (state) => ({ NeoProducts: state.NeoProducts });
const mapDispatchToProps = { addOrUpdateNeoVentes, addNeoVentesSC };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(NeoMiniPayment));
